import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { stripAccents } from '@app/helpers/StringHelper';

import { mapVcuCustomer } from '@app/pages/customers/Helpers/Customer';
import { updateCustomer } from '@app/store/customers/customers.thunk';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import RowInfo from '@app/partials/content/RowInfo';

const VehicleKnow = ({ showModal, setShowModal }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userData } = useSelector((state) => state.auth);
    const { params } = useSelector((state) => state.modal);
    const [activeTab, setActiveTab] = useState(0);
    const Intl = useIntl();

    const vehicles = params?.vehicles;
    let customer = { ...params?.customer };

    const handleClose = () => {
        setShowModal(false);
    };

    const handleChange = (value) => {
        vehicles[activeTab] = { ...vehicles[activeTab], isClientVehicle: value };
        customer.vehicleKnow = [...vehicles];
        const id = customer?.id;
        customer = mapVcuCustomer({ ...customer });
        customer.user = {
            id: userData.id,
            firstname: userData.first_name,
            lastname: userData.last_name,
        };

        dispatch(updateCustomer(id, customer, history, false));
    };

    return (
        <ModalDefault
            show={showModal}
            hideModal={handleClose}
            icon={<i className="la la-2x text-primary la-car" />}
            title={Intl.formatMessage({ id: `CUSTOMERS.MODAL.VEHICLE_KNOW.TITLE` })}
            body={
                <Tabs
                    className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x vehicle-tabs"
                    defaultActiveKey={activeTab}
                    activeKey={activeTab}
                    id={activeTab}
                    onSelect={(e) => setActiveTab(e)}
                >
                    {vehicles &&
                        vehicles.map((vehicle, index) => (
                            <Tab
                                eventKey={index}
                                title={`${vehicle?.brand} ${vehicle?.model}`}
                                key={vehicle.vehicleUuid}
                            >
                                <RowInfo
                                    label={
                                        <img
                                            src={`/media/marques/${stripAccents(vehicle?.brand).toLowerCase()}.svg`}
                                            alt={stripAccents(vehicle?.brand).toLowerCase()}
                                            width={40}
                                        />
                                    }
                                    value={
                                        <div className="immat-plate">
                                            <span className="plate plate-left">
                                                O<br />F
                                            </span>
                                            <span className="immat">{vehicle?.registrationNumber || '-'}</span>
                                            <span className="plate plate-right">
                                                O<br />F
                                            </span>
                                        </div>
                                    }
                                />
                                {/* Réimplémenter cette fonctionnalité à la suite du rework des véhicules connus */}
                                {/* <Permission permissions={['LEAD_UPDATE_VEHICLES_KNOW_CONTACT']}>
                                    <RowInfo
                                        label={Intl.formatMessage({
                                            id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.IS_CLIENT_VEHICLE',
                                        })}
                                        value={
                                            <SwitchInput
                                                triState={vehicle?.isClientVehicle === undefined}
                                                active={
                                                    vehicle?.isClientVehicle === undefined
                                                        ? null
                                                        : vehicle?.isClientVehicle
                                                }
                                                form={false}
                                                name="isClientVehicle"
                                                onChange={handleChange}
                                                labelFirst="Non"
                                                labelSecond="Oui"
                                            />
                                        }
                                    />
                                </Permission> */}
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.SOURCES' })}
                                    value={
                                        vehicle?.listEventsResume && vehicle?.listEventsResume.length
                                            ? vehicle?.listEventsResume.map((event, indexEvent) => (
                                                  <span key={vehicle.vehicleUuid}>
                                                      {event?.srcApplicationName} - {event?.processLabel}
                                                      {dayjs(event?.lastSrcProcessMoment).isValid() &&
                                                          ` - ${dayjs(event?.lastSrcProcessMoment).format(
                                                              'DD/MM/YYYY',
                                                          )}`}
                                                      {vehicle?.listEventsResume?.length &&
                                                          indexEvent !== vehicle.listEventsResume.length - 1 && <br />}
                                                  </span>
                                              ))
                                            : '-'
                                    }
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.ACTIVITY' })}
                                    value={
                                        vehicle?.listEventsResume?.length > 0
                                            ? vehicle?.listEventsResume[0].processLabel
                                            : '-'
                                    }
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.BRAND' })}
                                    value={vehicle?.brand || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.MODEL' })}
                                    value={vehicle?.model || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.VERSION' })}
                                    value={vehicle?.version || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.IMMAT' })}
                                    value={vehicle?.registrationNumber || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.COLOR' })}
                                    value={vehicle?.color || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.FUEL' })}
                                    value={vehicle?.energy || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.MILEAGE' })}
                                    value={
                                        vehicle?.lastMileageKnown ? (
                                            <NumberFormat
                                                value={vehicle?.lastMileageKnown}
                                                decimalSeparator="."
                                                thousandSeparator=" "
                                                displayType="text"
                                                suffix=" km"
                                            />
                                        ) : (
                                            '-'
                                        )
                                    }
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.TYPE' })}
                                    value={vehicle?.lastProductType || '-'}
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.MEC' })}
                                    value={
                                        vehicle?.circulationDate
                                            ? dayjs(vehicle?.circulationDate, [
                                                  'DD-MM-YYYY',
                                                  'YYYY-MM-DD HH:mm:ss',
                                                  'YYYY-MM-DD',
                                              ]).format('DD/MM/YYYY')
                                            : '-'
                                    }
                                />
                                <RowInfo
                                    label={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.VEHICLE_KNOW.PRICE' })}
                                    value={
                                        vehicle?.sellingPrice ? (
                                            <NumberFormat
                                                value={vehicle?.sellingPrice}
                                                decimalSeparator="."
                                                thousandSeparator=" "
                                                displayType="text"
                                                suffix=" €"
                                            />
                                        ) : (
                                            '-'
                                        )
                                    }
                                />
                            </Tab>
                        ))}
                </Tabs>
            }
            footer={
                <div className="m-auto">
                    <Button variant="primary" onClick={handleClose}>
                        {Intl.formatMessage({ id: `TRANSLATOR.CLOSE` })}
                    </Button>
                </div>
            }
        />
    );
};

export default VehicleKnow;

import classNames from 'classnames';
import { debounce, isArray } from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { toAbsoluteUrl } from '@src/_metronic';

import { getCustomerActions } from '@app/crud/customers/customer.crud';

import { useAppSelector, useFetch } from '@app/hooks';

import Loader from '@app/partials/content/Loader';

import { Icon } from '@app/components/Icon';
import { IconSize } from '@app/components/Icon/Icon';

import type { ActionsBlockVehicle } from './ActionsBlock.type';
import CustomerActionsVehicle from './CustomerActionsVehicle';
import { ActionsBlockSchema } from './schemas/ActionsBlock.schema';
import './ActionsBlock.scss';

const CustomerActions = () => {
    const ref = useRef(null);
    const { data: customer } = useAppSelector((state) => state.customer.customer);
    const {
        fetch,
        data: vehicles,
        loading = false,
    } = useFetch({
        fetchAction: getCustomerActions,
        resultInterceptor: (result) => {
            const parsedResult = ActionsBlockSchema.safeParse(result.result);

            if (!parsedResult.success) {
                // Todo handle error with Sentry
            }

            return parsedResult.success ? parsedResult.data : result.result;
        },
    });

    const [scrollState, setScrollState] = useState({
        isScrolled: false,
        isAtStart: true,
        isAtEnd: false,
    });

    useLayoutEffect(() => {
        const handleScroll = debounce(() => {
            if (ref.current) {
                const scrollableElement = ref.current.querySelector('.nav-tabs'); // Adjust selector if necessary
                if (scrollableElement) {
                    const { scrollLeft, scrollWidth, clientWidth } = scrollableElement;
                    setScrollState({
                        isScrolled: scrollLeft > 0,
                        isAtStart: scrollLeft === 0,
                        isAtEnd: scrollLeft + clientWidth >= scrollWidth,
                    });
                }
            }
        }, 100);

        const scrollableElement = ref.current?.querySelector('.nav-tabs'); // Adjust selector if necessary

        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [vehicles]);

    const [activeTab, setActiveTab] = useState(null);

    useEffect(() => {
        if (customer?.id) {
            fetch(customer.id);
        }
    }, [customer.id, fetch]);

    useEffect(() => {
        if (vehicles) {
            setActiveTab(Object.keys(vehicles)[0]);
        }
    }, [vehicles]);

    return (
        <Card className="actions-block card--shadowed position-relative">
            <Card.Header>
                <div className="d-flex align-items-center">
                    <Icon icon="cart-arrow-down" size={IconSize.TwoX} />
                    <FormattedHTMLMessage id="ACTIONS_BLOCK.TITLE" />
                </div>
            </Card.Header>
            {loading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            {!loading && !isArray(vehicles) ? (
                <Card.Body className="position-relative p-5">
                    <div
                        ref={ref}
                        className={classNames(`nav-track`, {
                            'is-scrolled': scrollState.isScrolled,
                            'is-at-start': scrollState.isAtStart,
                            'is-at-end': scrollState.isAtEnd,
                        })}
                    >
                        <Tabs
                            className="mb-0 nav-tabs"
                            activeKey={activeTab}
                            id={activeTab}
                            onSelect={(e) => setActiveTab(e)}
                        >
                            {Object.entries(vehicles)?.length > 0
                                ? Object.entries(vehicles)?.map(
                                      ([key, value]: [string, ActionsBlockVehicle]) =>
                                          value && (
                                              <Tab
                                                  eventKey={key}
                                                  title={key}
                                                  tabClassName={`link-tab-${key}-event text-gray-500 font-weight-semibold flex-shrink-0`}
                                                  className={`content-tab-${key}-event bg-peach mb-0 p-3 rounded font-size-base`}
                                                  key={key}
                                              >
                                                  <CustomerActionsVehicle vehicle={value} />
                                              </Tab>
                                          ),
                                  )
                                : null}
                        </Tabs>
                    </div>
                </Card.Body>
            ) : (
                <div
                    className="actions-block__placeholder d-flex flex-column gap-5 px-20 py-40 align-items-center justify-content-between text-center"
                    style={{ opacity: '0.5' }}
                >
                    <i>
                        <img src={toAbsoluteUrl(`/media/icons/car-crossed.svg`)} height={35} alt="Icon" />
                    </i>
                    <FormattedHTMLMessage id="ACTIONS_BLOCK.NO_VEHICLE" />
                </div>
            )}
        </Card>
    );
};

export default CustomerActions;

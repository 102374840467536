import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import useFetch from '@app/hooks/useFetch';

import { getOneCustomerActivity } from '@app/crud/customers/customer.crud';
import { EventDetails, LeadDetails } from '@app/crud/customers/customer.type';

import { useAppSelector } from '@app/hooks';
import useAction from '@app/pages/customers/Hooks/useAction';
import APVRequestQuickReply from '@app/pages/customers/Modals/APVRequestQuickReply';

import ChatMessage from '@app/partials/content/ChatMessage/ChatMessage';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';
import ErrorForm from '@app/partials/layout/ErrorForm';

import { RBRef } from './APVRequest';

type APVRequestReplyProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
};

const APVRequestReply = ({ showModal, setShowModal }: APVRequestReplyProps) => {
    const Intl = useIntl();
    const [lastEvent, setLastEvent] = useState<EventDetails>();
    const { register, setValue, handleSubmit, errors } = useForm();
    const { params } = useAppSelector((state) => state.modal);
    const [combineTagComment, setCombineTagComment] = useState<boolean>(false);
    const [selectedLabel, setSelectedLabel] = useState<string | undefined>();
    const [commentPlaceholder, setCommentPlaceholder] = useState<string>(
        Intl.formatMessage({
            id: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REPLY.PLACEHOLDER',
        }),
    );
    const [loading, createAction] = useAction({
        type: 'reply_local_apv',
        dataInterceptor: (data: { comment: string }) => {
            if (combineTagComment && selectedLabel) {
                data.comment = `${selectedLabel} - ${data.comment}`;
            }

            return data;
        },
        success: () => {
            setShowModal(false);
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.CONTACT.ERROR' }),
            });
        },
    });

    const {
        fetch: fetchActivity,
        data: lead,
        loading: fetchLoading,
    } = useFetch<string, LeadDetails, LeadDetails>({
        fetchAction: getOneCustomerActivity,
    });

    useEffect(() => {
        if (params?.leadId && fetchActivity) {
            fetchActivity(params.leadId);
        }
    }, [params, fetchActivity]);

    useEffect(() => {
        if (Array.isArray(lead?.events)) {
            const openingRequests = lead?.events.filter(
                (item) => item.eventType.slug === 'create_lead_local' || item.eventType.slug === 'add_answer_lead_apv',
            );

            if (openingRequests.length) {
                setLastEvent(openingRequests[0]);
            }
        }
    }, [lead]);

    const onSubmit = (data: { default_dealership: string }) => {
        delete data.default_dealership;

        // @ts-ignore
        createAction(data);
    };

    const onError = () => {
        toast({
            variant: 'danger',
            message: Intl.formatMessage({ id: 'FORM.ERROR.VALIDATE' }),
        });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={loading || fetchLoading}
            hideModal={() => setShowModal(false)}
            icon={<i className="la la-2x text-primary la-hand-pointer" />}
            title={Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.TITLE' })}
            body={
                <>
                    {lastEvent?.action?.message && (
                        <>
                            <FormattedHTMLMessage
                                id="CUSTOMERS.MODAL.LOCAL_APV_REPLY.INTRODUCTION"
                                values={{
                                    name: `${lastEvent?.user?.firstname} ${lastEvent?.user?.lastname}`,
                                    role: lastEvent?.user?.role,
                                    date: dayjs(lastEvent?.date).format('DD/MM/YYYY - HH [h] mm'),
                                }}
                            />
                            <ChatMessage message={lastEvent.action.message} />
                        </>
                    )}

                    <APVRequestQuickReply
                        setMessage={(value, selectedSubReply, asPlaceholder = false) => {
                            setSelectedLabel(selectedSubReply);

                            if (asPlaceholder) {
                                setValue('comment', '');
                                setCommentPlaceholder(value);
                                setCombineTagComment(true);
                                return;
                            }
                            setValue('comment', value);
                            setCombineTagComment(undefined);
                            setCommentPlaceholder(
                                Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REPLY.PLACEHOLDER' }),
                            );
                        }}
                    />

                    <Form.Group className="mt-5">
                        <Form.Label>
                            <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_APV_REPLY.COMMENT.LABEL" />
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            ref={
                                register({
                                    required: Intl.formatMessage({ id: 'FORM.ERROR.REQUIRED' }),
                                }) as RBRef
                            }
                            name="comment"
                            placeholder={commentPlaceholder}
                            rows={4}
                        />
                        <ErrorForm errors={errors} name="comment" />
                    </Form.Group>
                </>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" onClick={handleSubmit(onSubmit, onError)}>
                        {Intl.formatMessage({ id: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.ACTIONS.SEND' })}
                    </Button>
                </div>
            }
        />
    );
};

export default APVRequestReply;

import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '@src/_metronic';

import Label from '@app/partials/content/Label';

import type { ActionsBlockMaintenanceEvent } from '../../../ActionsBlock.type';
import ActionsBlockContext from '../../../ActionsBlockContext';

const isVehicleUnderFourYears = (circulationDate: string) => {
    const timeDiff = new Date().getTime() - new Date(circulationDate).getTime();

    return timeDiff < 4 * 365 * 24 * 60 * 60 * 1000;
};

const hasErronedDate = (circulationDate: string) => circulationDate === '1900-01-01';

const MaintenanceEvent = ({ event }: { event: ActionsBlockMaintenanceEvent }) => {
    const isEmpty = Object.keys(event.ICARE).length === 0 && Object.keys(event.NON_ICARE).length === 0;
    const { vehicle } = useContext(ActionsBlockContext);
    const isElligibleToMaintenance =
        !isVehicleUnderFourYears(vehicle.CIRCULATION_START_DATE) && !hasErronedDate(vehicle.CIRCULATION_START_DATE);
    return (
        <div className="w-50 p-5">
            <img
                src={toAbsoluteUrl(`/media/logos/captives/autosphere-maintenance.svg`)}
                height={21}
                alt="Logo Autosphere Maintenance"
            />
            <div>
                {(isEmpty || (!Array.isArray(event.NON_ICARE) && isElligibleToMaintenance)) && (
                    <>
                        <p className="my-1 font-weight-semibold font-size-sm">
                            <FormattedMessage id="ACTIONS_BLOCK.NO_CONTRACT" />
                        </p>
                        <Label variant="danger" className="font-weight-bold">
                            <FormattedMessage id="ACTIONS_BLOCK.ACTION.SUGGEST" />
                        </Label>
                        <p className="font-weight-semibold font-size-sm mt-1 mb-0 text-danger">
                            &bull; <FormattedMessage id="ACTIONS_BLOCK.CHECK_ELIGIBILITY" />
                        </p>
                    </>
                )}
                {!Array.isArray(event.ICARE) && event.ICARE.ICARE_NB_MOIS_RESTANT > 0 && (
                    <>
                        <p className="my-1 font-weight-semibold font-size-sm">
                            <FormattedMessage id="ACTIONS_BLOCK.ONGOING_CONTRACT" />
                        </p>
                        <Label variant="primary" className="font-weight-bold">
                            <FormattedMessage id="ACTIONS_BLOCK.ONGOING" />
                        </Label>
                        <span className="font-weight-semibold font-size-sm mx-1 text-primary">&bull;</span>
                        <span className="font-weight-semibold font-size-sm text-primary">
                            <FormattedMessage
                                id="ACTIONS_BLOCK.REMAINING_MONTHS"
                                values={{ months: event.ICARE.ICARE_NB_MOIS_RESTANT }}
                            />
                        </span>
                    </>
                )}
                {!isEmpty && !isElligibleToMaintenance && (
                    <div className="font-weight-semibold font-size-sm mt-1">
                        <FormattedMessage id="ACTIONS_BLOCK.NOT_ELIGIBLE" />
                        <p>
                            <span className="mr-1">
                                {!Array.isArray(event.NON_ICARE) && event.NON_ICARE.IS_VN ? (
                                    <FormattedMessage id="ACTIONS_BLOCK.VN" />
                                ) : (
                                    <FormattedMessage id="ACTIONS_BLOCK.MEC" />
                                )}{' '}
                                &bull;
                            </span>
                            <i className="la la-hourglass-half mr-1" style={{ color: 'inherit' }} />- 4 ans
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MaintenanceEvent;

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Label from '@app/partials/content/Label';

import type { ActionsBlockAPVEvent } from '../../../ActionsBlock.type';
import { isAboveThreshold } from '../../../utils/ActionsBlockUtils';

const MONTH_THRESHOLD = 12;

const APVEvent = ({ event }: { event: ActionsBlockAPVEvent | [] }) => {
    const isEmpty = Array.isArray(event) && event.length === 0;
    const needAction = !Array.isArray(event) && isAboveThreshold(event.APV_NB_MOIS_EVENT, MONTH_THRESHOLD);

    return (
        <div>
            <p className="my-1 font-weight-semibold font-size-sm">
                {isEmpty ? (
                    <FormattedMessage id="ACTIONS_BLOCK.APV.NO_INVOICE" />
                ) : (
                    <FormattedMessage id="ACTIONS_BLOCK.APV.LAST_INVOICE" />
                )}
            </p>
            {!Array.isArray(event) && event.APV_NB_MOIS_EVENT && (
                <div
                    className={classNames('font-size-sm', {
                        'text-danger': needAction,
                        'text-primary': !needAction,
                    })}
                >
                    <span className="font-weight-bold">&bull;</span>
                    <i className="ml-1 la la-hourglass-half" style={{ color: 'inherit' }} />
                    <span className="font-weight-bold">
                        {event.APV_NB_MOIS_EVENT < MONTH_THRESHOLD ? '-' : '+'} {MONTH_THRESHOLD}{' '}
                        <FormattedMessage id="ACTIONS_BLOCK.MONTH" />
                    </span>
                </div>
            )}
            {isEmpty && (
                <Label variant="danger" className="font-weight-bold">
                    <FormattedMessage id="ACTIONS_BLOCK.ACTION.SUGGEST" />
                </Label>
            )}
        </div>
    );
};

export default APVEvent;

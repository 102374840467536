import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '@src/_metronic';

import Label from '@app/partials/content/Label';

import { ActionsBlockBuyEvent, ActionsBlockFundingEvents } from '../../ActionsBlock.type';

const getPeriod = (event: ActionsBlockBuyEvent) => {
    if (event.ACHAT_NB_MOIS_EVENT > 36) {
        return {
            color: 'danger',
            description: '+ de 3 ans',
            action: (
                <Label variant="danger" className="font-weight-bold">
                    <FormattedHTMLMessage id="ACTIONS_BLOCK.FUNDING.SUGGEST_RENEWAL" />
                </Label>
            ),
        };
    } else if (event.ACHAT_NB_MOIS_EVENT >= 11 && event.ACHAT_NB_MOIS_EVENT <= 13) {
        return {
            color: 'primary',
            description: '+ de 1 an',
            action: (
                <Label variant="primary">
                    <FormattedHTMLMessage id="ACTIONS_BLOCK.FUNDING.CONTACT_FOR_ANNIVERSARY" />
                </Label>
            ),
        };
    }

    return {
        color: 'primary',
        description: '- de 3 ans',
        action: (
            <>
                <Label variant="primary" className="font-weight-bold">
                    <FormattedMessage id="ACTIONS_BLOCK.FUNDING.RECENT_BUY" />
                </Label>
                <div className="text-primary font-weight-bold font-size-sm mt-1">
                    &bull;
                    <i className="la la-hourglass-half" style={{ color: 'inherit' }} />
                    <span>
                        {event.ACHAT_NB_MOIS_EVENT} <FormattedMessage id="ACTIONS_BLOCK.MONTH" />
                    </span>
                </div>
            </>
        ),
    };
};

export interface ActionsBlockFundingProps {
    events: ActionsBlockFundingEvents;
}

const ActionsBlockFunding = ({ events }: ActionsBlockFundingProps) => {
    const isEmpty = Object.keys(events.ACHAT).length === 0 && Object.keys(events.FINANCEMENT).length === 0;
    const { description, action } = !Array.isArray(events.ACHAT) && getPeriod(events.ACHAT);

    return (
        <>
            {isEmpty && (
                <div className="d-flex py-5 font-size-sm">
                    <p className="font-weight-semibold w-50">
                        <FormattedHTMLMessage id="ACTIONS_BLOCK.FUNDING.NO_INVOICE" />
                    </p>
                    <Label variant="danger" className="font-weight-bold">
                        <FormattedHTMLMessage id="ACTIONS_BLOCK.FUNDING.PROPOSE_NEW_CAR" />
                    </Label>
                </div>
            )}
            {Object.keys(events.ACHAT).length > 0 && Object.keys(events.FINANCEMENT).length === 0 && (
                <div className="d-flex py-5">
                    <div className="w-50 font-size-sm">
                        <span className="font-weight-semibold">Achat &bull; </span>
                        <i className="ml-1 la la-hourglass-half" style={{ color: 'inherit' }} />
                        <span className="font-weight-semibold">{description}</span>
                    </div>
                    <div>{action}</div>
                </div>
            )}
            {!Array.isArray(events.FINANCEMENT) &&
                (events.FINANCEMENT.FUNDING_NB_MOIS_RESTANT > 0 ? (
                    <div className="d-flex align-items-center py-5">
                        <div className="w-50">
                            <img
                                src={toAbsoluteUrl(`/media/logos/captives/fi-autosphere-financement.svg`)}
                                height={21}
                                alt="Logo autosphere financement"
                            />
                        </div>
                        <div>
                            <Label variant="primary" className="font-weight-bold">
                                <FormattedMessage id="ACTIONS_BLOCK.ONGOING" />
                            </Label>
                            <span className="font-weight-bold font-size-sm mx-1 text-primary">&bull; &nbsp;</span>
                            <span className="font-weight-bold font-size-sm text-primary">
                                <FormattedMessage
                                    id="ACTIONS_BLOCK.REMAINING_MONTHS"
                                    values={{ months: events.FINANCEMENT.FUNDING_NB_MOIS_RESTANT }}
                                />
                            </span>
                        </div>
                    </div>
                ) : (
                    <p className="font-weight-semibold font-size-sm">
                        <FormattedMessage id="ACTIONS_BLOCK.FUNDING.EXPIRED_CONTRACT" />
                    </p>
                ))}
        </>
    );
};

export default ActionsBlockFunding;

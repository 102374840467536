import { z } from 'zod';

export const AchatSchema = z.object({
    ACHAT_DATE_EVENT: z.string(),
    ACHAT_NB_MOIS_EVENT: z.number(),
});

export const FinancementSchema = z.object({
    FUNDING_DATE_END_EVENT: z.string(),
    FUNDING_DATE_EVENT: z.string(),
    FUNDING_NB_MOIS_EVENT: z.number(),
    FUNDING_NB_MOIS_RESTANT: z.number(),
});

export const BLOC_ACHAT_FINANCEMENT_Schema = z.object({
    ACHAT: z.array(z.any()).length(0).or(AchatSchema),
    FINANCEMENT: z.array(z.any()).length(0).or(FinancementSchema),
});

export const BCS_Schema = z.object({
    BCS_DATE_EVENT: z.string(),
    BCS_NB_MOIS_EVENT: z.number(),
});

export const FACTURE_APV_Schema = z.object({
    APV_DATE_EVENT: z.string(),
    APV_NB_MOIS_EVENT: z.number(),
});

export const TSCAN_Schema = z.object({
    NB_PB_TSCAN: z.number(),
    TSCAN_DATE_EVENT: z.string(),
    TSCAN_NB_MOIS_EVENT: z.number(),
    TSCAN_RECOMMENDATION: z.string(),
});

export const BLOC_APRES_VENTE_Schema = z.object({
    BCS: z.array(z.any()).length(0).or(BCS_Schema),
    FACTURE_APV: z.array(z.any()).length(0).or(FACTURE_APV_Schema),
    TSCAN: z.array(z.any()).length(0).or(TSCAN_Schema),
});

export const CIRANO_Schema = z.object({
    CIRANO_DATE_EVENT: z.string(),
    CIRANO_NB_MOIS_RESTANT: z.number(),
    CIRANO_NOM_CONTRAT: z.string(),
});

export const OPTEVEN_Schema = z.object({
    OPTEVEN_DATE_END_EVENT: z.string(),
    OPTEVEN_DATE_EVENT: z.string(),
    OPTEVEN_NB_MOIS_RESTANT: z.number(),
    OPTEVEN_NOM_CONTRAT: z.string(),
});

export const BLOC_GARANTIE_EXTENSION_GARANTIE_Schema = z.object({
    CIRANO: z.array(z.any()).length(0).or(CIRANO_Schema),
    OPTEVEN: z.array(z.any()).length(0).or(OPTEVEN_Schema),
});

export const ICARE_Schema = z.object({
    ICARE_DATE_END_EVENT: z.string(),
    ICARE_DATE_EVENT: z.string(),
    ICARE_NB_MOIS_EVENT: z.number(),
    ICARE_NB_MOIS_RESTANT: z.number(),
});

export const NON_ICARE_Schema = z.object({
    IS_VN: z.boolean(),
    IS_VO: z.boolean(),
    NICARE_ACHAT_DATE_EVENT: z.string(),
    NICARE_STATUT: z.string(),
});

export const CONTRAT_DE_MAINTENANCE_Schema = z.object({
    ICARE: z.array(z.any()).length(0).or(ICARE_Schema),
    NON_ICARE: z.array(z.any()).length(0).or(NON_ICARE_Schema),
});

export const SECURYCAR_FIN_DE_CONTRAT_Schema = z.object({
    SECURYCAR_FC_DATE_END_EVENT: z.string(),
    SECURYCAR_FC_DATE_EVENT: z.string(),
    SECURYCAR_FC_NB_MOIS_EVENT: z.number(),
});

export const SECURYCAR_NON_FIN_DE_CONTRAT_Schema = z.object({
    SECURYCAR_NFC_DATE_END_EVENT: z.string(),
    SECURYCAR_NFC_DATE_EVENT: z.string(),
    SECURYCAR_NFC_NB_MOIS_EVENT: z.number(),
});

export const SECURYCAR_Schema = z.object({
    SECURYCAR_FIN_DE_CONTRAT: z.array(z.any()).length(0).or(SECURYCAR_FIN_DE_CONTRAT_Schema),
    SECURYCAR_NON_FIN_DE_CONTRAT: z.array(z.any()).length(0).or(SECURYCAR_NON_FIN_DE_CONTRAT_Schema),
});

export const BLOC_SERVICES_AUTOSPHERE_Schema = z.object({
    CONTRAT_DE_MAINTENANCE: CONTRAT_DE_MAINTENANCE_Schema,
    SECURYCAR: SECURYCAR_Schema,
});

export const EVENTS_Schema = z.object({
    BLOC_ACHAT_FINANCEMENT: BLOC_ACHAT_FINANCEMENT_Schema,
    BLOC_APRES_VENTE: BLOC_APRES_VENTE_Schema,
    BLOC_GARANTIE_EXTENSION_GARANTIE: BLOC_GARANTIE_EXTENSION_GARANTIE_Schema,
    BLOC_SERVICES_AUTOSPHERE: BLOC_SERVICES_AUTOSPHERE_Schema,
});

export const VEHICLE_INFO_Schema = z.object({
    BRAND: z.string(),
    CIRCULATION_START_DATE: z.string(),
    IS_SALE_PRODUCT: z.boolean(),
    LAST_EVENT: z.string(),
    MODEL: z.string(),
    NB_EVENT: z.number(),
    REGISTRATION_NUMBER_NORM: z.string().nullable(),
    VEHICLE_UUID: z.string(),
});

export const VEHICLE_Schema = z.object({
    EVENTS: z.array(z.any()).length(0).or(EVENTS_Schema),
    VEHICLE: VEHICLE_INFO_Schema,
});

// eslint-disable-next-line import/prefer-default-export
export const ActionsBlockSchema = z.record(VEHICLE_Schema);

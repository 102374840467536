import { Divider } from 'primereact/divider';
import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { FormattedHTMLMessage, useIntl } from 'react-intl';

import { useAppSelector } from '@app/hooks';

import Loader from '@app/partials/content/Loader';

import Price from '@app/components/formatters/price/Price';

import BusinessChild from './BusinessChild';
import BusinessRowInfo from './BusinessRowInfo';

import './Business.scss';

const CustomerBusiness = () => {
    const Intl = useIntl();
    const { business: businessStore } = useAppSelector((state) => state.customer);
    const { loading, data: business } = businessStore;
    const userData = useAppSelector((state) => state.auth.userData);
    const context = userData?.context?.toUpperCase();

    return (
        <Card className="card--shadowed multi">
            <Card.Header>
                <div className="d-flex align-items-center">
                    <i className="la la-money la-2x text-light mr-2" />
                    <span>{Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.TITLE' })}</span>
                </div>
            </Card.Header>
            <Card.Body
                className="position-relative p-0 d-flex justify-content-between"
                style={loading ? { paddingTop: '4.50rem', paddingBottom: '4.50rem' } : null}
            >
                {loading ? (
                    <Loader style={{ width: '5rem', height: '5rem' }} overlay wrapperStyle={{ top: '0', left: '0' }} />
                ) : (
                    <div className="d-flex flex-wrap">
                        {(business && businessStore.error) || !business ? (
                            <Col className="text-center p-5">
                                <span className="font-weight-normal">
                                    {Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.NO_COMMAND' }, { context })}
                                </span>
                            </Col>
                        ) : (
                            <>
                                <div className="w-50 p-5">
                                    <BusinessChild
                                        icon="car"
                                        title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.CARD.VO.TITLE' })}
                                    >
                                        <strong>
                                            <BusinessRowInfo
                                                title={Intl.formatMessage(
                                                    {
                                                        id: 'CUSTOMER.VIEW.BUSINESS.CARD.VO.NB_BUY',
                                                    },
                                                    {
                                                        number: business?.turnoverCategories?.turnoverVo
                                                            ?.activitiesCounter,
                                                    },
                                                )}
                                                value=""
                                            />
                                        </strong>
                                        <BusinessRowInfo
                                            title={Intl.formatMessage({
                                                id: 'CUSTOMER.VIEW.BUSINESS.BUDGET',
                                            })}
                                            value={
                                                <Price
                                                    value={
                                                        business?.turnoverCategories?.turnoverVo?.averageTurnover || '-'
                                                    }
                                                />
                                            }
                                        />
                                    </BusinessChild>
                                </div>
                                <Divider
                                    layout="vertical"
                                    className="p-divider-dashed my-5 mx-0"
                                    style={{ minHeight: 'inherit' }}
                                />
                                <div className="w-50 p-5">
                                    <BusinessChild
                                        icon="car"
                                        title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.CARD.VN.TITLE' })}
                                    >
                                        <strong>
                                            <BusinessRowInfo
                                                title={Intl.formatMessage(
                                                    {
                                                        id: 'CUSTOMER.VIEW.BUSINESS.CARD.VO.NB_BUY',
                                                    },
                                                    {
                                                        number: business?.turnoverCategories?.turnoverVn
                                                            ?.activitiesCounter,
                                                    },
                                                )}
                                                value=""
                                            />
                                        </strong>
                                        <BusinessRowInfo
                                            title={Intl.formatMessage({
                                                id: 'CUSTOMER.VIEW.BUSINESS.BUDGET',
                                            })}
                                            value={
                                                <Price
                                                    value={
                                                        business?.turnoverCategories?.turnoverVn?.averageTurnover || '-'
                                                    }
                                                />
                                            }
                                        />
                                    </BusinessChild>
                                </div>
                                <Divider className="mx-5 my-0 p-divider-dashed" />
                                <div className="w-50 p-5">
                                    <BusinessChild
                                        icon="wrench"
                                        title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.CARD.APV.TITLE' })}
                                    >
                                        <BusinessRowInfo
                                            title={Intl.formatMessage({
                                                id: 'CUSTOMER.VIEW.BUSINESS.CARD.APV.NB_BENEFITS',
                                            })}
                                            value={business?.turnoverCategories?.turnoverApv?.activitiesCounter}
                                        />
                                        <BusinessRowInfo
                                            title={Intl.formatMessage({
                                                id: 'CUSTOMER.VIEW.BUSINESS.BUDGET',
                                            })}
                                            value={
                                                <Price
                                                    value={
                                                        business?.turnoverCategories?.turnoverApv?.averageTurnover ||
                                                        '-'
                                                    }
                                                />
                                            }
                                        />
                                    </BusinessChild>
                                </div>
                                <Divider
                                    layout="vertical"
                                    className="p-divider-dashed my-5 mx-0"
                                    style={{ minHeight: 'inherit' }}
                                />
                                <div className="w-50 p-5">
                                    <BusinessChild
                                        icon="chart-bar"
                                        title={Intl.formatMessage({ id: 'CUSTOMER.VIEW.BUSINESS.CARD.CA.TITLE' })}
                                    >
                                        <FormattedHTMLMessage
                                            id="CUSTOMER.VIEW.BUSINESS.CARD.CA.CA_TOTAL"
                                            values={{
                                                ca: `${
                                                    Number(business?.fullTurnover)
                                                        .toFixed(2)
                                                        .replace('.', ',')
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') || '0'
                                                }\u00A0€`,
                                                ca_last: `${
                                                    Number(business?.twelveMonthsTurnover)
                                                        .toFixed(2)
                                                        .replace('.', ',')
                                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') || '0'
                                                }\u00A0€`,
                                            }}
                                        />
                                    </BusinessChild>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default CustomerBusiness;

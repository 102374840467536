import React from 'react';

import { Icon } from '@app/components/Icon';
import type { Icons } from '@app/components/Icon/Icon.type';

type BusinessChildProps = {
    title: string;
    icon: Icons;
    children: React.ReactNode;
};

const BusinessChild = ({ title, icon, children }: BusinessChildProps) => (
    <>
        <div className="text-dark font-size-lg font-weight-semibold d-flex align-items-center">
            <Icon icon={icon} className="mr-2" />
            {title}
        </div>
        {children}
    </>
);

export default BusinessChild;

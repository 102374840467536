import { Divider } from 'primereact/divider';
import React from 'react';

import { ActionsBlockWarrantyEvents } from '../../ActionsBlock.type';

import CiranoEvent from './events/CiranoEvent';
import OptevenEvent from './events/OptevenEvent';

export interface ActionsBlockWarrantyProps {
    events: ActionsBlockWarrantyEvents;
}

const ActionsBlockWarranty = ({ events }: ActionsBlockWarrantyProps) => (
    <div className="d-flex">
        {/* @ts-ignore */}
        <CiranoEvent event={events.CIRANO} />
        <Divider layout="vertical" className="p-divider-dashed my-5 mx-0" style={{ minHeight: 'inherit' }} />
        {/* @ts-ignore */}
        <OptevenEvent event={events.OPTEVEN} />
    </div>
);

export default ActionsBlockWarranty;

import classNames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import { empty } from '@app/helpers/ToolsHelper';

import { FiltersContext } from '../FiltersProvider';
import TriSwitchInput from '../TriSwitchInput';

type SwitchInputFilterProps = {
    name: string;
    values: {
        label?: string;
        labelFirst?: string;
        value: string;
        labelSecond: string;
    }[];
    onChange?: Function;
    defaultValue?: {
        key: string;
        value: string;
    };
    triState?: null;
    className: string;
    pending: boolean;
    onPendingChange: Function;
    format: string;
    separator: string;
};

const SwitchInputFilter = ({
    name,
    values,
    onChange,
    defaultValue,
    triState,
    className,
    pending,
    onPendingChange,
    format,
    separator,
}: SwitchInputFilterProps) => {
    const filters = useContext(FiltersContext);

    const isChecked = (currentValue: string) => {
        if (filters[name]?.value) {
            if (typeof filters[name].value === 'string') {
                return filters[name].value.includes(currentValue);
            }
            return filters[name].value === currentValue;
        }
        return false;
    };

    const filterHandler = (value: string) => {
        let checkedValue = filters[name]?.value || null;
        let data;

        if (isChecked(value)) {
            data = { value: '' };
            if (format === 'string' && separator) {
                // switchInput with same value and string: 'vo,vd,vn'
                checkedValue = checkedValue.split(separator);
                checkedValue.splice(checkedValue.indexOf(value), 1);
                data = {
                    value: checkedValue.join(separator),
                    format,
                    separator,
                };
            }
        } else {
            data = { value };
            if (checkedValue && format === 'string' && separator) {
                // switchInput with same key: 'vo,vd,vn'
                data = {
                    value: `${checkedValue}${separator}${value}`,
                    format,
                    separator,
                };
            }
        }
        return data;
    };

    useEffect(() => {
        if (!filters[name] && defaultValue) {
            handleClick(defaultValue.key, defaultValue.value);
        }
    }, [defaultValue, filters]);

    const handleClick = (value: string, labelFirst: string, labelSecond?: string) => {
        const valueFilter = {
            [name]: { ...filterHandler(value), labelFirst, labelSecond },
        };

        if (!pending) {
            onChange({ ...valueFilter }, defaultValue);
        } else {
            onPendingChange({ ...valueFilter }, defaultValue);
        }
    };

    return (
        <>
            {values.map(
                ({ value, labelFirst, labelSecond }: { value: string; labelFirst?: string; labelSecond?: string }) => {
                    const active = isChecked(value);
                    return (
                        <div
                            key={value}
                            className={classNames('switch-input', className, {
                                'switch-input--active': active === true,
                                'switch-input--inactive': active === false || empty(active),
                                'switch-input--neutral': active === null && triState,
                            })}
                        >
                            {labelFirst && (
                                <Form.Label className={`switch-input__label ${active ? '' : 'p-inputswitch-checked'}`}>
                                    {labelFirst}
                                </Form.Label>
                            )}
                            <TriSwitchInput
                                state={active}
                                triState
                                onChange={() => handleClick(value, labelFirst, labelSecond)}
                            />
                            {labelSecond && (
                                <Form.Label className={`switch-input__label ${active ? 'p-inputswitch-checked' : ''}`}>
                                    {labelSecond}
                                </Form.Label>
                            )}
                        </div>
                    );
                },
            )}
        </>
    );
};

SwitchInputFilter.defaultProps = {
    className: '',
    triState: false,
    pending: false,
    format: null,
    separator: null,
};

export default SwitchInputFilter;

import React from 'react';

import { Icons } from './Icon.type';

export enum IconSize {
    XS = 'la-xs',
    SM = 'la-sm',
    LG = 'la-lg',
    XL = 'la-xl',
    TwoXL = 'la-2xl',
    OneX = 'la-1x',
    TwoX = 'la-2x',
    ThreeX = 'la-3x',
    FourX = 'la-4x',
    FiveX = 'la-5x',
    SixX = 'la-6x',
    SevenX = 'la-7x',
    EightX = 'la-8x',
    NineX = 'la-9x',
    TenX = 'la-10x',
}

interface IconProps extends React.HTMLAttributes<HTMLElement> {
    icon: Icons;
    size?: IconSize;
}

const Icon = ({ icon, size, ...props }: IconProps) => (
    <i className={`la la-${icon} ${size} ${props.className}`} style={{ color: 'inherit' }} />
);

export default Icon;

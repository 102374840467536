import * as H from 'history';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { Intl } from '@src/_metronic/i18n/I18nProvider';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getPricingState, getVehicle, getVehicleHistoricalPrice, getVehicles } from '@app/crud/stock/stock.crud';

import ROUTES from '@app/router/Routes';
import { PricingStateEnum } from '@app/store/stock/stock.type';

import toast from '@app/partials/content/Toast';

import { actions } from './stock.store';

export function fetchVehicles(params: any): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchVehicles());

        getVehicles(params)
            .then((response) => {
                if (response?.results) {
                    dispatch(actions.fetchedVehicles(response.results));
                } else {
                    dispatch(actions.fetchVehiclesFailed({}));
                }
            })
            .catch(() => {
                dispatch(actions.fetchVehiclesFailed({}));
            });
    };
}

export function fetchVehicle({
    sku,
    history,
    context,
}: {
    sku: string;
    history?: H.History;
    context: string;
}): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchVehicle());
        getVehicle(sku, context)
            .then((data) => {
                if (data?.error && sku) {
                    toast({
                        onShow: history.push(routeTo(ROUTES.STOCK_VEHICLES.PATH)),
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                    dispatch(actions.fetchVehicleFailed({}));
                } else {
                    dispatch(actions.fetchedVehicle(data));
                }
            })
            .catch(() => {
                toast({
                    onShow: history.push(routeTo(ROUTES.STOCK_VEHICLES.PATH)),
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
                dispatch(actions.fetchVehicleFailed({}));
            });
    };
}

export function fetchVehicleHistoricalValue({
    sku,
    history,
}: {
    sku: string;
    history?: H.History;
}): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.fetchVehicleHistoricalPrice());
        getVehicleHistoricalPrice(sku)
            .then((data) => {
                if (data?.results?.error && sku) {
                    toast({
                        onShow: history.push(routeTo(ROUTES.STOCK_VEHICLES.PATH)),
                        variant: 'danger',
                        message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                    });
                    dispatch(actions.fetchVehicleHistoricalPriceFailed({}));
                } else {
                    dispatch(actions.fetchedVehicleHistoricalPrice(data.results));
                }
            })
            .catch(() => {
                toast({
                    onShow: history.push(routeTo(ROUTES.STOCK_VEHICLES.PATH)),
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
                dispatch(actions.fetchVehicleHistoricalPriceFailed({}));
            });
    };
}

export function fetchPricingState(): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        getPricingState().then((data) => {
            dispatch(actions.fetchPricingState(data.state));
        });
    };
}

export function setPricingState(pricingState: PricingStateEnum): ThunkAction<void, object, object, AnyAction> {
    return (dispatch) => {
        dispatch(actions.setPricingState(pricingState));
    };
}

import classNames from 'classnames';
import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';
import React from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

type APVRequestQuickReplyProps = {
    setMessage: (comment: string, selectedSubReply: string, asPlaceholder?: boolean) => void;
};

type quickRepliesType = {
    key: string;
    title: string;
    commentRequired: boolean;
    subReplies: {
        key: string;
        value: string;
        placeholder?: string;
    }[];
}[];

const APVRequestQuickReply = ({ setMessage }: APVRequestQuickReplyProps) => {
    const Intl = useIntl();
    const [selectedReply, setSelectedReply] = React.useState<string | undefined>();
    const [selectedSubReply, setSelectedSubReply] = React.useState<string | undefined>();

    const quickReplies: quickRepliesType = [
        {
            key: 'reach_success',
            title: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.SUCCESS.LABEL',
            commentRequired: false,
            subReplies: [
                { key: 'request_treated', value: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.SUCCESS.REQUEST_TREATED' },
            ],
        },
        {
            key: 'reach_failed',
            title: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.FAILED.LABEL',
            commentRequired: true,
            subReplies: [
                {
                    key: 'message',
                    value: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.FAILED.MESSAGE',
                    placeholder: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.FAILED.PLACEHOLDER',
                },
                {
                    key: 'no_message',
                    value: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.FAILED.NO_MESSAGE',
                    placeholder: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.FAILED.PLACEHOLDER',
                },
            ],
        },
        {
            key: 'reach_not_needed',
            title: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.NOT_NEEDED.LABEL',
            commentRequired: false,
            subReplies: [
                { key: 'request_treated', value: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.NOT_NEEDED.REQUEST_TREATED' },
            ],
        },
    ];

    const reachLabels = quickReplies.map((reply) => ({
        title: Intl.formatMessage({ id: reply.title }),
        code: reply.key,
    }));

    const reachReplies =
        quickReplies
            .find((reply) => reply.key === selectedReply)
            ?.subReplies.map((reply) => ({
                placeholder: reply?.placeholder,
                title: Intl.formatMessage({ id: reply.value }),
                code: reply.key,
            })) || [];

    const handleReply = (value: string) => {
        const reply = reachReplies.find((r) => r.code === value);

        if (value === selectedSubReply) {
            setSelectedSubReply(undefined);
            setMessage('', '');
            return;
        }

        setSelectedSubReply(value);

        if (reply?.placeholder) {
            setMessage(Intl.formatMessage({ id: reply.placeholder }), reply.title, true);
        } else {
            setMessage(Intl.formatMessage({ id: reply.title }), reply.title);
        }
    };

    return (
        <>
            <Form.Group className="mt-5">
                <Form.Label>
                    <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.LABEL" />
                </Form.Label>
                <Dropdown
                    value={selectedReply}
                    onChange={(e) => {
                        setSelectedReply(e.value);
                        setSelectedSubReply(undefined);
                        setMessage('', '');
                    }}
                    options={reachLabels}
                    optionLabel="title"
                    placeholder={Intl.formatMessage({
                        id: 'CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.PLACEHOLDER',
                    })}
                    optionValue="code"
                />
            </Form.Group>
            {selectedReply && (
                <Form.Group className="mt-5">
                    <Form.Label>
                        <FormattedMessage id="CUSTOMERS.MODAL.LOCAL_APV_REPLY.REACH.QUICK_REPLY.LABEL" />
                    </Form.Label>

                    <div>
                        {reachReplies.map((reply) => (
                            <Chip
                                key={reply.code}
                                template={
                                    <button
                                        type="button"
                                        onClick={() => handleReply(reply.code)}
                                        className="p-chip-child"
                                    >
                                        {reply.title}
                                        {selectedSubReply === reply.code && <i className="ml-2 las la-1x la-times" />}
                                    </button>
                                }
                                className={classNames('font-weight-semibold my-1 mr-2', {
                                    'p-chip-warning': selectedSubReply === reply.code,
                                    'p-chip-outline-secondary': selectedSubReply !== reply.code,
                                })}
                            />
                        ))}
                    </div>
                </Form.Group>
            )}
        </>
    );
};

export default APVRequestQuickReply;

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '@src/_metronic';

import type { ActionsBlockSecuryCarEvent } from '@app/pages/customers/Partials/ActionsBlock/ActionsBlock.type';
import {
    calculateRemainingMonths,
    hasExpired,
} from '@app/pages/customers/Partials/ActionsBlock/utils/ActionsBlockUtils';

import Label from '@app/partials/content/Label';

const SecuryCar = ({ event }: { event: ActionsBlockSecuryCarEvent }) => {
    const isEmpty =
        Object.keys(event.SECURYCAR_FIN_DE_CONTRAT).length === 0 &&
        Object.keys(event.SECURYCAR_NON_FIN_DE_CONTRAT).length === 0;
    const hasCanceledContract = Object.keys(event.SECURYCAR_FIN_DE_CONTRAT).length > 0;
    const hasActiveContract = Object.keys(event.SECURYCAR_NON_FIN_DE_CONTRAT).length > 0;
    const hasExpiredContract =
        !Array.isArray(event.SECURYCAR_NON_FIN_DE_CONTRAT) &&
        hasExpired(event.SECURYCAR_NON_FIN_DE_CONTRAT.SECURYCAR_NFC_DATE_END_EVENT);
    const monthsRemaining =
        !Array.isArray(event.SECURYCAR_NON_FIN_DE_CONTRAT) &&
        calculateRemainingMonths(event.SECURYCAR_NON_FIN_DE_CONTRAT.SECURYCAR_NFC_DATE_END_EVENT);
    const toRenew = monthsRemaining <= 9;

    return (
        <div className="w-50 py-5">
            <img
                src={toAbsoluteUrl(`/media/logos/captives/autosphere-securite.svg`)}
                height={21}
                alt="Logo Autosphere sécurité"
            />
            {hasActiveContract && !hasExpiredContract && (
                <>
                    <p className="my-1 font-weight-semibold font-size-sm">
                        <FormattedMessage id="ACTIONS_BLOCK.ONGOING_CONTRACT" />
                    </p>
                    <div
                        className={classNames('font-size-sm', {
                            'text-danger': toRenew,
                            'text-primary': !toRenew,
                        })}
                    >
                        <Label variant={toRenew ? 'danger' : 'primary'} className="font-weight-bold">
                            {toRenew ? (
                                <FormattedMessage id="ACTIONS_BLOCK.ACTION.TO_RENEW" />
                            ) : (
                                <FormattedMessage id="ACTIONS_BLOCK.ONGOING" />
                            )}
                        </Label>
                        <span className="font-weight-bold mx-1">&bull;</span>
                        <span className="font-weight-bold">
                            <FormattedMessage
                                id="ACTIONS_BLOCK.REMAINING_MONTHS"
                                values={{ months: monthsRemaining }}
                            />
                        </span>
                    </div>
                </>
            )}
            {(hasCanceledContract || hasExpiredContract) && (
                <p className="mt-1 font-weight-semibold font-size-sm">
                    <FormattedMessage id="ACTIONS_BLOCK.EXPIRED_CONTRACT" />
                </p>
            )}
            {isEmpty && (
                <p className="mt-1 font-weight-semibold font-size-sm">
                    <FormattedMessage id="ACTIONS_BLOCK.NO_CONTRACT" />
                </p>
            )}
        </div>
    );
};

export default SecuryCar;

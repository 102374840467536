import React, { useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';

import { anonymizeUsers } from '@app/crud/autouser/user.crud';

import variantTypes from '@app/constants';
import { useAppSelector } from '@app/hooks';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

interface ManageAnonymizationProps {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
    onComplete: () => void;
}

const ManageAnonymization = ({ showModal, setShowModal, onComplete }: ManageAnonymizationProps) => {
    const Intl = useIntl();
    const [fullName, setFullName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { selectedUsers } = useAppSelector((state) => state.rgpd);

    useEffect(() => {
        const firstName = selectedUsers.at(0)?.firstName;
        const lastName = selectedUsers.at(0)?.lastName;
        const fullNameCleaned = firstName || lastName ? `${firstName || ''} ${lastName || ''}`.trim() : undefined;

        setFullName(fullNameCleaned);
    }, [selectedUsers]);

    const handleError = () => {
        toast({
            variant: variantTypes.DANGER,
            message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
        });
    };

    const onSubmit = () => {
        setIsLoading(true);
        const usersToAnonymize = selectedUsers.map((item) => ({
            application: item.application,
            table: item.table,
            id: item.id.toString(),
            alias: item.alias,
        }));

        anonymizeUsers(usersToAnonymize)
            .then((response) => {
                if (response?.errors.length > 0) {
                    handleError();
                } else {
                    selectedUsers.forEach((user) => {
                        user.pending = true;
                    });
                    toast({
                        variant: variantTypes.SUCCESS,
                        message: Intl.formatMessage({ id: 'TRANSLATOR.SUCCESS' }),
                    });
                }
            })
            .catch(() => {
                handleError();
            })
            .finally(() => {
                setIsLoading(false);
                onComplete();
            });
    };

    return (
        <ModalDefault
            show={showModal}
            loading={isLoading}
            hideModal={() => setShowModal(false)}
            title={
                selectedUsers.length === 1 ? (
                    <FormattedHTMLMessage id="CONSOLE_RGPD.MODAL.CONFIRMATION.TITLE" values={{ name: fullName }} />
                ) : (
                    <FormattedHTMLMessage id="CONSOLE_RGPD.MODAL.CONFIRMATION_MULTIPLE.TITLE" />
                )
            }
            body={
                <div className="text-center">
                    <FormattedMessage id="CONSOLE_RGPD.MODAL.CONFIRMATION.MESSAGE" />
                </div>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" className="mr-5" onClick={() => setShowModal(false)}>
                        <FormattedMessage id="TRANSLATOR.CANCEL" />
                    </Button>
                    <Button variant="primary" onClick={onSubmit}>
                        <FormattedMessage id="TRANSLATOR.VALIDATE" />
                    </Button>
                </div>
            }
        />
    );
};

export default ManageAnonymization;

import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '@app/hooks';
import { actions } from '@app/store/modal/modal.store';

const ConsoleRGPDSubHeader = () => {
    const dispatch = useAppDispatch();
    const { selectedUsers, isButtonVisible } = useAppSelector((state) => state.rgpd);

    const handleAnonymizeClick = () => {
        dispatch(actions.modalChange('manage_anonymization'));
    };

    return (
        <div id="kt_subheader" className="kt-subheader kt-grid__item">
            <Card.Header className="d-flex align-items-center justify-content-between">
                <FormattedHTMLMessage id="CONSOLE_RGPD.TITLE" />
                {isButtonVisible && (
                    <Button
                        className="p-2 px-3"
                        variant="primary"
                        onClick={handleAnonymizeClick}
                        disabled={selectedUsers.length === 0}
                    >
                        <FormattedMessage id="CONSOLE_RGPD.TABLE.ACTION.ANONYMIZE_MULTIPLE" />
                    </Button>
                )}
            </Card.Header>
        </div>
    );
};

export default withRouter(ConsoleRGPDSubHeader);

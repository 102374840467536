import { createContext } from 'react';

import type { ActionsBlockVehicleInfo } from './ActionsBlock.type';

interface ActionsBlockContextInterface {
    vehicle: ActionsBlockVehicleInfo;
}

const ActionsBlockContext = createContext({} as ActionsBlockContextInterface);

export const ActionsBlockProvider = ActionsBlockContext.Provider;
export const ActionsBlockConsumer = ActionsBlockContext.Consumer;

export default ActionsBlockContext;

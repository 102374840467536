import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '@src/_metronic';

import Label from '@app/partials/content/Label';

import type { ActionsBlockCiranoEvent } from '../../../ActionsBlock.type';
import { isBelowThreshold } from '../../../utils/ActionsBlockUtils';

const MONTH_THRESHOLD = 6;

const CiranoEvent = ({ event }: { event: ActionsBlockCiranoEvent | [] }) => {
    const isEmpty = Object.keys(event).length === 0;
    const toRenew = !Array.isArray(event) && isBelowThreshold(event.CIRANO_NB_MOIS_RESTANT, MONTH_THRESHOLD);

    return (
        <div className="w-50 py-5">
            <img src={toAbsoluteUrl(`/media/logos/captives/cirano.svg`)} height={21} alt="Logo Marque" />
            <p className="my-1 font-weight-semibold font-size-sm">
                {isEmpty && <FormattedMessage id="ACTIONS_BLOCK.NO_CONTRACT" />}
                {!isEmpty &&
                    !Array.isArray(event) &&
                    (event.CIRANO_NB_MOIS_RESTANT > 0 ? (
                        event.CIRANO_NOM_CONTRAT
                    ) : (
                        <FormattedMessage id="ACTIONS_BLOCK.EXPIRED_CONTRACT" />
                    ))}
            </p>
            {!isEmpty && !Array.isArray(event) && event.CIRANO_NB_MOIS_RESTANT > 0 && (
                <>
                    <Label variant={toRenew ? 'danger' : 'primary'} className="font-weight-bold">
                        {toRenew ? (
                            <FormattedMessage id="ACTIONS_BLOCK.ACTION.TO_RENEW" />
                        ) : (
                            <FormattedMessage id="ACTIONS_BLOCK.ONGOING" />
                        )}
                    </Label>
                    <span
                        className={classNames('font-size-sm', {
                            'text-danger': toRenew,
                            'text-primary': !toRenew,
                        })}
                    >
                        <span className="font-weight-bold mx-1">&bull;</span>
                        <span className="font-weight-bold">
                            <FormattedMessage
                                id="ACTIONS_BLOCK.REMAINING_MONTHS"
                                values={{ months: event.CIRANO_NB_MOIS_RESTANT }}
                            />
                        </span>
                    </span>
                </>
            )}
        </div>
    );
};

export default CiranoEvent;

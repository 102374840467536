import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import usePermissions from '@app/hooks/usePermissions';

import AskPricingButton from '@app/partials/content/Pricing/AskPricingButton/AskPricingButton';

export default function StockVehiclesFiltersQuick({
    resetAllFilters,
    isOpenFiltersBlock,
    setIsOpenFiltersBlock,
    isDisabledResetButton,
}) {
    const { hasPermission } = usePermissions();

    return (
        <Row>
            <Col className="d-flex justify-content-end">
                {hasPermission('STOCK_MANUAL_PRICING') && <AskPricingButton />}
                <Button
                    className=" h-100"
                    variant="outline-secondary"
                    onClick={() => setIsOpenFiltersBlock(!isOpenFiltersBlock)}
                >
                    {isOpenFiltersBlock ? (
                        <div>
                            <i className="las la-eye-slash" />
                            <FormattedMessage id="STOCK_VEHICLES.FILTERS.BUTTON.HIDE_FILTERS" />
                        </div>
                    ) : (
                        <div>
                            <i className="las la-eye" />
                            <FormattedMessage id="STOCK_VEHICLES.FILTERS.BUTTON.DISPLAY_MORE_FILTERS" />
                        </div>
                    )}
                </Button>
                <Button className="ml-4 h-100" disabled={isDisabledResetButton} onClick={() => resetAllFilters()}>
                    <i className="las la-trash-alt" />
                    <FormattedMessage id="STOCK_VEHICLES.FILTERS.BUTTON.RESET" />
                </Button>
            </Col>
        </Row>
    );
}

import { Divider } from 'primereact/divider';
import React from 'react';

import { ActionsBlockServicesEvents } from '../../ActionsBlock.type';

import MaintenanceEvent from './events/MaintenanceEvent';
import SecuryCarEvent from './events/SecuryCarEvent';

export interface ActionsBlockServicesProps {
    events: ActionsBlockServicesEvents;
}

const ActionsBlockServices = ({ events }: ActionsBlockServicesProps) => (
    <div className="d-flex">
        <SecuryCarEvent event={events.SECURYCAR} />
        <Divider layout="vertical" className="p-divider-dashed my-5 mx-0" style={{ minHeight: 'inherit' }} />
        <MaintenanceEvent event={events.CONTRAT_DE_MAINTENANCE} />
    </div>
);

export default ActionsBlockServices;

import React from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';

export default function StockIndicator({
    textColor,
    bgColor,
    title,
    totalStockPublished,
    totalStock,
    onClick,
    classNames,
}) {
    const percentage = totalStock ? Math.round((100 * totalStockPublished) / totalStock) : 0;

    return (
        <Card
            className={`cursor-pointer stock-indicator text-${textColor} bg-${bgColor} ${classNames}`}
            onClick={onClick}
        >
            <div className="text-center p-4">
                <div>
                    <span className="stock-indicator_title-publish">{title}</span>
                    <div className="stock-indicator_number-publish">
                        <NumberFormat
                            value={totalStockPublished}
                            decimalSeparator="."
                            thousandSeparator=" "
                            displayType="text"
                        />
                    </div>
                </div>
                <div className="mb-2">
                    <span className="stock-indicator_title-eligibility">
                        <FormattedMessage id="STOCK_VEHICLES.INDICATOR.TOTAL" />
                    </span>
                    <div className="stock-indicator_number-eligibility">
                        <NumberFormat
                            value={totalStock}
                            decimalSeparator="."
                            thousandSeparator=" "
                            displayType="text"
                        />
                    </div>
                </div>
                <ProgressBar now={percentage} variant="white" className={`progress-bg-${bgColor}`} />
                <Card.Text className="text-right">
                    <span>{percentage}&nbsp;%</span>
                </Card.Text>
            </div>
        </Card>
    );
}

import { Divider } from 'primereact/divider';
import React from 'react';

import type { ActionsBlockAfterSalesEvents } from '../../ActionsBlock.type';

import { APVEvent, BCSEvent, TscanEvent } from './events';

export interface ActionsBlockAfterSalesProps {
    events: ActionsBlockAfterSalesEvents;
}

const ActionsBlockAfterSales = ({ events }: ActionsBlockAfterSalesProps) => (
    <>
        {/* @ts-ignore */}
        <APVEvent event={events.FACTURE_APV} />
        <div className="d-flex">
            {/* @ts-ignore */}
            <BCSEvent event={events.BCS} />
            <Divider layout="vertical" className="p-divider-dashed my-5 mx-0" style={{ minHeight: 'inherit' }} />
            {/* @ts-ignore */}
            <TscanEvent event={events.TSCAN} />
        </div>
    </>
);

export default ActionsBlockAfterSales;

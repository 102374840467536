import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface PriceProps extends NumberFormatProps {
    value: number | string;
    className?: string;
}

const Price = ({ value, className, ...props }: PriceProps) => {
    let v = value;
    if (typeof value === 'string') {
        v = parseFloat(value);

        if (Number.isNaN(v)) {
            return <span>{value}</span>;
        }
    }

    return <NumberFormat value={v} className={className} {...props} />;
};

Price.defaultProps = {
    thousandSeparator: ' ',
    decimalScale: 2,
    decimalSeparator: ',',
    displayType: 'text',
    suffix: '\u00A0€',
    className: '',
};

export default Price;

import { Divider } from 'primereact/divider';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import Label from '@app/partials/content/Label';

import type {
    ActionsBlockAfterSalesEvents,
    ActionsBlockFundingEvents,
    ActionsBlockServicesEvents,
    ActionsBlockWarrantyEvents,
} from './ActionsBlock.type';
import { ActionsBlockSections } from './ActionsBlock.type';
import ActionsBlockContext from './ActionsBlockContext';
import ActionsBlockAfterSales from './components/aftersales/ActionsBlockAfterSales';
import ActionsBlockFunding from './components/funding/ActionsBlockFunding';
import ActionsBlockServices from './components/services/ActionsBlockServices';
import ActionsBlockWarranty from './components/warranty/ActionsBlockWarranty';

const isKnownSection = (section: string) =>
    Object.values(ActionsBlockSections).includes(section as ActionsBlockSections);

const sections: { [key in ActionsBlockSections]: { title: React.ReactElement; content: any } } = {
    BLOC_ACHAT_FINANCEMENT: {
        title: <FormattedMessage id="ACTIONS_BLOCK.FUNDING.TITLE" />,
        content: ActionsBlockFunding,
    },
    BLOC_APRES_VENTE: {
        title: <FormattedMessage id="ACTIONS_BLOCK.APV.TITLE" />,
        content: ActionsBlockAfterSales,
    },
    BLOC_GARANTIE_EXTENSION_GARANTIE: {
        title: <FormattedMessage id="ACTIONS_BLOCK.WARRANTY.TITLE" />,
        content: ActionsBlockWarranty,
    },
    BLOC_SERVICES_AUTOSPHERE: {
        title: <FormattedMessage id="ACTIONS_BLOCK.SERVICES.TITLE" />,
        content: ActionsBlockServices,
    },
};

type CustomerActionsSectionProps = {
    type: ActionsBlockSections;
    events:
        | ActionsBlockFundingEvents
        | ActionsBlockAfterSalesEvents
        | ActionsBlockWarrantyEvents
        | ActionsBlockServicesEvents;
};

const CustomerActionsSection = ({ type, events }: CustomerActionsSectionProps) => {
    if (!isKnownSection(type)) return null;

    const { vehicle } = useContext(ActionsBlockContext);
    const section = sections[type as ActionsBlockSections];
    const SectionContent = sections[type as ActionsBlockSections].content;

    return (
        <>
            <div className="d-flex align-items-end justify-content-between mt-3 mb-1">
                <h4 className="text-uppercase font-weight-bold font-size-base mb-0">{section.title}</h4>
                {type === ActionsBlockSections.BLOC_APRES_VENTE && vehicle.IS_SALE_PRODUCT && (
                    <Label variant="dark-orange" className="font-weight-bold">
                        <FormattedMessage id="ACTIONS_BLOCK.ELIGIBLE_LIFE_CT" />
                    </Label>
                )}
            </div>
            <Divider className="my-2 p-divider-dashed" />
            <SectionContent events={events} />
        </>
    );
};

export default CustomerActionsSection;

import React from 'react';

type BusinessRowInfoProps = {
    title: React.ReactNode;
    value: React.ReactNode;
};

const BusinessRowInfo = ({ title, value }: BusinessRowInfoProps) => (
    <div>
        {title} <strong className="d-inline-block">{value}</strong>
    </div>
);

export default BusinessRowInfo;

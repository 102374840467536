import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useAppSelector, useModal } from '@app/hooks';
import { AskPricingModal, CancelPricingModal } from '@app/pages/stock/pricing/modals';
import { fetchPricingState } from '@app/store/stock/stock.thunk';
import { PricingStateEnum } from '@app/store/stock/stock.type';

export default function AskPricingButton() {
    const pricingState = useAppSelector((state) => state.stock.pricingState);
    const [showAskPricingModal, toggleAskPricingModal] = useModal(false, 'ask_pricing_modal');
    const [showCancelPricingModal, toggleCancelPricingModal] = useModal(false, 'cancel_pricing_modal');
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPricingState());
    }, []);

    return pricingState === PricingStateEnum.PLANNED ? (
        <>
            <Button className="mr-4 h-100" onClick={() => toggleCancelPricingModal()}>
                <FormattedMessage id="STOCK.PRICING.CANCEL_PRICING" />
            </Button>
            <CancelPricingModal showModal={showCancelPricingModal} setShowModal={toggleCancelPricingModal} />
        </>
    ) : (
        <>
            <Button
                className="mr-4 h-100"
                disabled={pricingState === PricingStateEnum.IN_PROGRESS}
                onClick={() => toggleAskPricingModal()}
            >
                <FormattedMessage id="STOCK.PRICING.ASK_PRICING" />
            </Button>
            <AskPricingModal showModal={showAskPricingModal} setShowModal={toggleAskPricingModal} />
        </>
    );
}

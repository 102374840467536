import { AnyAction } from 'redux';

import { UserGDPR } from '@app/crud/autouser/user.types';

export const actionTypes = {
    UpdateSelectedUsers: 'UPDATE_SELECTED_USERS',
    UpdateButtonVisibility: 'UPDATE_BUTTON_VISIBILITY',
};

export interface GDPRState {
    selectedUsers: UserGDPR[];
    isButtonVisible: boolean;
}

const initialState: GDPRState = {
    selectedUsers: [],
    isButtonVisible: false,
};

export function rgpdReducer(state = initialState, action: AnyAction): GDPRState {
    switch (action.type) {
        case actionTypes.UpdateSelectedUsers: {
            return {
                ...state,
                selectedUsers: action.payload,
            };
        }
        case actionTypes.UpdateButtonVisibility: {
            return {
                ...state,
                isButtonVisible: action.payload,
            };
        }
        default:
            return state;
    }
}

export const actions = {
    UpdateUsersSelected: (selectedUsers: UserGDPR[]) => ({
        type: actionTypes.UpdateSelectedUsers,
        payload: selectedUsers,
    }),
    UpdateButtonVisibility: (isButtonVisible: boolean) => ({
        type: actionTypes.UpdateButtonVisibility,
        payload: isButtonVisible,
    }),
};

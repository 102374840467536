import React from 'react';
import { FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '@src/_metronic';

import Label from '@app/partials/content/Label';

import { ActionsBlockBCSEvent } from '../../../ActionsBlock.type';
import { isBelowThreshold } from '../../../utils/ActionsBlockUtils';

const MONTH_THRESHOLD = 12;

const getTitle = (event: ActionsBlockBCSEvent) => {
    if (event.BCS_NB_MOIS_EVENT) {
        if (isBelowThreshold(event.BCS_NB_MOIS_EVENT, MONTH_THRESHOLD)) {
            return <FormattedMessage id="ACTIONS_BLOCK.BCS.RECENT_AUDIT" />;
        }
        return <FormattedMessage id="ACTIONS_BLOCK.BCS.NO_RECENT_AUDIT" />;
    }
    return <FormattedMessage id="ACTIONS_BLOCK.BCS.NO_AUDIT" />;
};

const BCSEvent = ({ event }: { event: ActionsBlockBCSEvent }) => {
    const isEmpty = Object.keys(event).length === 0;

    return (
        <div className="w-50 py-5">
            <img src={toAbsoluteUrl(`/media/logos/captives/fleetback.svg`)} height={19} alt="Logo Marque" />
            <p className="my-1 font-weight-semibold font-size-sm">{getTitle(event)}</p>
            {!isBelowThreshold || isEmpty || event.BCS_NB_MOIS_EVENT > 12 ? (
                <div className="d-flex align-items-center">
                    <Label variant="danger" className="font-weight-bold">
                        <FormattedMessage id="ACTIONS_BLOCK.ACTION.TO_DO" />
                    </Label>
                    {event.BCS_NB_MOIS_EVENT > 12 && (
                        <div className="text-danger ml-1 font-size-sm">
                            <span className="font-weight-bold">&bull;</span>
                            <i className="ml-1 la la-hourglass-half" style={{ color: 'inherit' }} />
                            <span className="font-weight-bold">
                                + {MONTH_THRESHOLD} <FormattedMessage id="ACTIONS_BLOCK.MONTH" />
                            </span>
                        </div>
                    )}
                </div>
            ) : (
                <div className="d-flex align-items-center">
                    <Label variant="primary" className="font-weight-bold">
                        <FormattedMessage id="ACTIONS_BLOCK.COMPLIANT" />
                    </Label>
                    <div className="text-primary ml-1 font-size-sm">
                        <span className="font-weight-bold">&bull;</span>
                        <i className="ml-1 la la-hourglass-half" style={{ color: 'inherit' }} />
                        <span className="font-weight-bold">
                            - {MONTH_THRESHOLD} <FormattedMessage id="ACTIONS_BLOCK.MONTH" />
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BCSEvent;

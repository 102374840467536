import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { cancelPricingRequest } from '@app/crud/stock/stock.crud';

import { setPricingState } from '@app/store/stock/stock.thunk';
import { PricingStateEnum } from '@app/store/stock/stock.type';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

type ModalProps = {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};

const CancelPricingModal = ({ showModal, setShowModal }: ModalProps) => {
    const [step, setStep] = useState(1);
    const dispatch = useDispatch();
    const Intl = useIntl();

    const cancelAskingPricing = () => {
        cancelPricingRequest().then(
            () => {
                setStep(2);
            },
            () => {
                toast({
                    variant: 'danger',
                    message: Intl.formatMessage({ id: 'STOCK.PRICING.MODAL.CANCEL.TOAST_ERROR' }),
                });
            },
        );
    };

    const exitModal = () => {
        setShowModal(false);
        step === 2 && dispatch(setPricingState(PricingStateEnum.AVAILABLE));
    };

    const modalIcon =
        step === 1 ? (
            <i className="las la-2x la-money-bill text-danger" />
        ) : (
            <i className="las la-2x la-thumbs-up text-success" />
        );

    const modalTitle = (
        <p className={step === 1 ? 'text-text-danger' : 'text-success'}>
            <FormattedMessage id={`STOCK.PRICING.MODAL.CANCEL.TITLE.STEP_${step}`} />
        </p>
    );

    const modalBody = (
        <p className="text-center">
            <FormattedHTMLMessage id={`STOCK.PRICING.MODAL.CANCEL.BODY.STEP_${step}`} />
        </p>
    );

    const modalFooter = (
        <div>
            {step === 1 ? (
                <div className="d-flex justify-content-center">
                    <Button variant="outline-secondary" onClick={() => exitModal()}>
                        <FormattedMessage id="TRANSLATOR.CLOSE" />
                    </Button>
                    <Button className="ml-4" variant="danger" onClick={() => cancelAskingPricing()}>
                        <FormattedMessage id="TRANSLATOR.CANCEL" />
                    </Button>
                </div>
            ) : (
                <div className="d-flex justify-content-center">
                    <Button variant="outline-secondary" onClick={() => exitModal()}>
                        <FormattedMessage id="TRANSLATOR.CLOSE" />
                    </Button>
                </div>
            )}
        </div>
    );

    return (
        <ModalDefault
            icon={modalIcon}
            show={showModal}
            hideModal={() => {
                exitModal();
            }}
            title={modalTitle}
            body={modalBody}
            footer={modalFooter}
        />
    );
};
export default CancelPricingModal;

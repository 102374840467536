import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { toAbsoluteUrl } from '@src/_metronic';

import { useAppSelector } from '@app/hooks';
import { actions } from '@app/store/modal/modal.store';

import { ActionsBlockSections, ActionsBlockVehicle } from './ActionsBlock.type';
import ActionsBlockContext from './ActionsBlockContext';
import ScrollToVehicleEventsButton from './components/partials/ScrollToVehicleEventsButton';
import CustomerActionsSection from './CustomerActionsSection';

const CustomerActionsVehicle = ({ vehicle }: { vehicle: ActionsBlockVehicle }) => {
    const dispatch = useDispatch();
    const { customer } = useAppSelector((state) => state.customer);
    const { VEHICLE: actionsBlockVehicle, EVENTS } = vehicle;
    return (
        <ActionsBlockContext.Provider
            value={React.useMemo(() => ({ vehicle: actionsBlockVehicle }), [actionsBlockVehicle])}
        >
            <header className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="font-size-base font-weight-bold mb-0">{`${actionsBlockVehicle?.BRAND} ${actionsBlockVehicle?.MODEL}`}</h3>
                <button
                    type="button"
                    className="btn btn-link text-primary p-0"
                    style={{ fontSize: '12px', whiteSpace: 'nowrap' }}
                    onClick={() =>
                        dispatch(
                            actions.modalChange('vehicle_know', {
                                customer,
                                vehicles: customer.data.vehicleKnow,
                            }),
                        )
                    }
                >
                    <FormattedHTMLMessage id="ACTIONS_BLOCK.VEHICLE_DETAILS" />
                </button>
            </header>
            {actionsBlockVehicle.NB_EVENT && actionsBlockVehicle.NB_EVENT > 0 && actionsBlockVehicle.VEHICLE_UUID && (
                <ScrollToVehicleEventsButton vehicle={actionsBlockVehicle} />
            )}
            {Object.entries(EVENTS).length > 0 ? (
                Object.entries(EVENTS).map(([type, events]) => (
                    <CustomerActionsSection key={type} type={type as ActionsBlockSections} events={events} />
                ))
            ) : (
                <div className="actions-block__placeholder d-flex flex-column gap-5 px-20 py-40 align-items-center justify-content-between text-center">
                    <i>
                        <img src={toAbsoluteUrl(`/media/icons/car-crossed.svg`)} height={35} alt="Icon" />
                    </i>
                    <FormattedMessage id="ACTIONS_BLOCK.VEHICLE.NO_EVENT" />
                </div>
            )}
        </ActionsBlockContext.Provider>
    );
};

export default CustomerActionsVehicle;

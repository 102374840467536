import classNames from 'classnames';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import { toAbsoluteUrl } from '@src/_metronic';

import Label from '@app/partials/content/Label';

import { ActionsBlockTSCANEvent } from '../../../ActionsBlock.type';
import { isAboveThreshold } from '../../../utils/ActionsBlockUtils';

const MONTH_THRESHOLD = 12;

const getTitle = (isEmpty: boolean, event: ActionsBlockTSCANEvent) => {
    if (isEmpty) {
        return <FormattedMessage id="ACTIONS_BLOCK.TSCAN.NO_AUDIT" />;
    } else if (event.NB_PB_TSCAN > 0) {
        return <FormattedHTMLMessage id="ACTIONS_BLOCK.TSCAN.PROBLEMS" values={{ count: event.NB_PB_TSCAN }} />;
    }
    return <FormattedMessage id="ACTIONS_BLOCK.TSCAN.NO_PROBLEM" />;
};

const TscanEvent = ({ event }: { event: ActionsBlockTSCANEvent }) => {
    const isEmpty = Object.keys(event).length === 0;
    const needAction = !isEmpty && isAboveThreshold(event.TSCAN_NB_MOIS_EVENT, MONTH_THRESHOLD);

    return (
        <div className="w-50 p-5">
            <img src={toAbsoluteUrl(`/media/logos/captives/tscan.svg`)} height={19} alt="Logo tSCAN" />
            <div className="d-flex flex-column my-1 font-weight-semibold font-size-sm">{getTitle(isEmpty, event)}</div>
            {!isEmpty ? (
                <div className="d-flex aling-items-center">
                    {event.NB_PB_TSCAN > 0 || needAction ? (
                        <Label variant="danger" className="font-weight-bold">
                            {event.TSCAN_RECOMMENDATION === 'REPLACE_NOW' ? (
                                <div className="d-flex align-items-center">
                                    <i className="la la-exclamation-triangle text-danger font-weight-bold mr-1" />
                                    <FormattedMessage id="ACTIONS_BLOCK.TSCAN.TYRES" />
                                </div>
                            ) : (
                                <FormattedMessage id="ACTIONS_BLOCK.ACTION.TO_DO" />
                            )}
                        </Label>
                    ) : (
                        <Label variant="primary" className="font-weight-bold">
                            <FormattedMessage id="ACTIONS_BLOCK.COMPLIANT" />
                        </Label>
                    )}
                    <div
                        className={classNames('d-flex align-items-center ml-2 font-size-sm', {
                            'text-danger': needAction,
                            'text-primary': !needAction,
                        })}
                    >
                        <span className="font-weight-bold">&bull;</span>
                        <i className="ml-1 la la-hourglass-half" style={{ color: 'inherit' }} />
                        <span className="font-weight-bold">
                            {isAboveThreshold(event.TSCAN_NB_MOIS_EVENT, MONTH_THRESHOLD) ? '+' : '-'} {MONTH_THRESHOLD}{' '}
                            <FormattedMessage id="ACTIONS_BLOCK.MONTH" />
                        </span>
                    </div>
                </div>
            ) : (
                <Label variant="danger" className="font-weight-bold">
                    <FormattedMessage id="ACTIONS_BLOCK.ACTION.TO_DO" />
                </Label>
            )}
        </div>
    );
};

export default TscanEvent;

import React, { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import useModal from '@app/hooks/useModal';

import Label from '@app/partials/content/Label';

import { isPublishAutosphere } from '../../helpers/StockRules';
import StockModalReasonPublicationVeh from '../../modals/StockModalReasonPublicationVeh';

const StockVehicleEligibilityPublication = ({ vehicle }) => {
    const [showReasonsModal, toggleReasonsModal] = useModal(false, 'reasons');
    const [slugOrigin, setSlugOrigin] = useState();
    const isContextBernard = useSelector((state) => state.auth.userData.context === 'bernard');

    const getStatusIcon = (value, type, isPublication = false) => {
        const currentState = ((isPublication && isPublishAutosphere(vehicle)) || !isPublication) && value;
        let hasInformation = true;
        let hasReason = false;

        if (!currentState) {
            switch (type) {
                case 'fw':
                    hasReason = isPublication ? false : vehicle?.fw_eligibility_warning?.length > 0;
                    break;
                case 'afr':
                    hasReason =
                        isPublication && !vehicle?.is_eligible_afr ? vehicle?.anomaly?.split(',')?.length > 0 : false;
                    break;
                case 'la_centrale':
                case 'leboncoin':
                    if (isPublication) {
                        hasInformation = false;
                    }
                    break;
                default:
                    break;
            }
        }

        return (
            <>
                <Label variant={currentState ? 'success' : 'danger'} className="p-0">
                    <i className={`las ${currentState ? 'la-check text-success' : 'la-times text-danger'}`} />
                </Label>
                {!currentState && hasReason && (
                    <p
                        className="mt-3 mb-0 text-danger font-italic cursor-pointer"
                        onClick={() => {
                            setSlugOrigin(`${isPublication ? 'publication' : 'eligibility'}_${type}`);
                            toggleReasonsModal(!showReasonsModal);
                        }}
                    >
                        <FormattedMessage id="STOCK_VEHICLE.CARD.STATUS.SHOW_REASONS" />
                    </p>
                )}
                {!currentState && !hasInformation && (
                    <p className="mt-3 mb-0 px-2 text-danger font-italic">
                        <FormattedMessage id="STOCK_VEHICLE.CARD.STATUS.NO_INFORMATION" />
                    </p>
                )}
            </>
        );
    };

    return (
        !isContextBernard && (
            <Card>
                <Card.Header>
                    <i className="card__icon la-lg las la-newspaper text-dark" />
                    <FormattedMessage id="STOCK_VEHICLE.CARD.ELIGIBILITY_PUBLICATION.TITLE" />
                </Card.Header>
                <Card.Body>
                    <div className="overflow-auto w-100">
                        {/* Header Row */}
                        <Row className="row-status">
                            <Col />
                            <Col className="font-weight-bold">
                                <FormattedMessage id="STOCK_VEHICLE.CARD.STATUS.A_FR" />
                            </Col>
                            <Col className="font-weight-bold">
                                <FormattedMessage id="STOCK_VEHICLE.CARD.STATUS.FW" />
                            </Col>
                            <Col className="font-weight-bold">
                                <FormattedMessage id="STOCK_VEHICLE.CARD.STATUS.FLUIDITY" />
                            </Col>
                            <Col className="font-weight-bold">
                                <FormattedMessage id="STOCK_VEHICLE.CARD.STATUS.LA_CENTRALE" />
                            </Col>
                            <Col className="font-weight-bold">
                                <FormattedMessage id="STOCK_VEHICLE.CARD.STATUS.LE_BON_COIN" />
                            </Col>
                        </Row>

                        {/* Eligibility Row */}
                        <Row className="mt-3 row-status">
                            <Col className="font-weight-bold">
                                <FormattedMessage id="STOCK_VEHICLE.CARD.STATUS.ELIGIBILITY" />
                            </Col>
                            <Col>
                                <div className="status-container">{getStatusIcon(vehicle.is_eligible_afr, 'afr')}</div>
                            </Col>
                            <Col>
                                <div className="status-container">{getStatusIcon(vehicle.is_eligible_fw, 'fw')}</div>
                            </Col>
                            <Col>
                                <div className="status-container">
                                    {getStatusIcon(vehicle.is_eligible_fluidite, 'fluidity')}
                                </div>
                            </Col>
                            <Col>
                                <div className="status-container">
                                    {getStatusIcon(
                                        isPublishAutosphere(vehicle) &&
                                            vehicle?.eligibilities?.afr_marketplace_lacentrale?.status,
                                        'la_centrale',
                                    )}
                                </div>
                            </Col>
                            <Col>
                                <div className="status-container">
                                    {getStatusIcon(
                                        isPublishAutosphere(vehicle) &&
                                            vehicle?.eligibilities?.afr_marketplace_leboncoin?.status,
                                        'leboncoin',
                                    )}
                                </div>
                            </Col>
                        </Row>

                        {/* Publication Row */}
                        <Row className="row-status">
                            <Col className="font-weight-bold">
                                <FormattedMessage id="STOCK_VEHICLE.CARD.STATUS.PUBLICATION" />
                            </Col>
                            <Col>
                                <div className="status-container">
                                    {getStatusIcon(isPublishAutosphere(vehicle), 'afr', true)}
                                </div>
                            </Col>
                            <Col>
                                <div className="status-container">{getStatusIcon(vehicle.is_delivery, 'fw', true)}</div>
                            </Col>
                            <Col>
                                <div className="status-container">
                                    {getStatusIcon(vehicle.is_fluidite, 'fluidity', true)}
                                </div>
                            </Col>
                            <Col>
                                <div className="status-container">{getStatusIcon(false, 'la_centrale', true)}</div>
                            </Col>
                            <Col>
                                <div className="status-container">{getStatusIcon(false, 'leboncoin', true)}</div>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>
                <StockModalReasonPublicationVeh
                    showModal={showReasonsModal}
                    setShowModal={toggleReasonsModal}
                    vehicle={vehicle}
                    slugOrigin={slugOrigin}
                />
            </Card>
        )
    );
};

export default StockVehicleEligibilityPublication;

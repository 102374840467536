export const isBelowThreshold = (value: number, threshold: number) => value >= 0 && value < threshold;
export const isAboveThreshold = (value: number, threshold: number) => value >= threshold;

export const hasExpired = (date: string) => {
    const future = new Date(date);
    const now = new Date();
    return future < now;
};

export const calculateRemainingMonths = (date: string) => {
    const future = new Date(date);
    const now = new Date();
    const yearsDiff = future.getFullYear() - now.getFullYear();
    const monthsDiff = future.getMonth() - now.getMonth();
    return yearsDiff * 12 + monthsDiff;
};

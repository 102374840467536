import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface LabelProps {
    variant?: string;
    size?: string;
    fontSize?: string;
    className?: string;
    children?: ReactNode;
    style?: React.CSSProperties;
}

function Label({ variant, size, fontSize, className, children, style, ...rest }: LabelProps) {
    return (
        <div
            className={classNames('label label-inline', {
                [size]: size,
                [fontSize]: fontSize,
                [`label-light-${variant}`]: variant,
                [className]: className,
            })}
            style={style}
            {...rest}
        >
            <div>{children}</div>
        </div>
    );
}

Label.defaultProps = {
    size: 'label-lg',
    fontSize: '',
};

export default Label;

import produce from 'immer';
import { AnyAction } from 'redux';

import { FastNote } from '@app/crud/administration/fast-notes.type';
import { Order } from '@app/crud/apv/order.type';
import { Customer, CustomerVehicle, ExternalData, LeadDetails } from '@app/crud/customers/customer.type';
import { Contract } from '@app/crud/securyweb/securyweb.types';

export const actionTypes = {
    ResetCustomers: 'RESET_CUSTOMERS',
    ResetCustomer: 'RESET_CUSTOMER',
    AddCustomer: 'ADD_CUSTOMER',
    UpdateCustomer: 'UPDATE_CUSTOMER',
    FetchCustomer: 'FETCH_CUSTOMER',
    FetchCustomers: 'FETCH_CUSTOMERS',
    FetchedCustomer: 'FETCHED_CUSTOMER',
    FetchedCustomers: 'FETCHED_CUSTOMERS',
    UpdatedCustomer: 'UPDATED_CUSTOMER',
    AddedCustomer: 'ADDED_CUSTOMER',
    FetchCustomerFailed: 'FETCH_CUSTOMER_FAILED',
    FetchCustomersFailed: 'FETCH_CUSTOMERS_FAILED',
    AddCustomerFailed: 'ADD_CUSTOMER_FAILED',
    UpdateCustomerFailed: 'UPDATE_CUSTOMER_FAILED',
    LeadUpdated: 'LEAD_UPDATED',
    FetchedParams: 'FETCHED_PARAMS',
    FetchedFinance: 'FETCHED_FINANCE',
    FetchedLeadReasons: 'FETCHED_LEAD_REASONS',
    FetchedTags: 'FETCHED_TAGS',
    FetchActionData: 'FETCH_ACTION_DATA',
    FetchedActionData: 'FETCHED_ACTION_DATA',
    FetchActionDataFailed: 'FETCH_ACTION_DATA_FAILED',
    FetchContracts: 'FETCH_CONTRACTS',
    FetchedContracts: 'FETCHED_CONTRACTS',
    FetchContractsFailed: 'FETCH_ACTION_CONTRACT_FAILED',
    FetchAPVOrders: 'FETCH_APV_ORDERS',
    FetchedAPVOrders: 'FETCHED_APV_ORDERS',
    FetchAPVOrdersFailed: 'FETCH_APV_ORDERS_FAILED',
    AddSelectedCustomers: 'ADD_SELECTED_CUSTOMER',
    RemoveSelectedCustomers: 'REMOVE_SELECTED_CUSTOMER',
};

export const leadTypes = {
    local: 'local',
    localVd: 'local_vd',
    localVo: 'local_vo',
    localVn: 'local_vn',
    localApv: 'local_apv',
    localHomeCharge: 'local_home_charge',
    localReprise: 'local_reprise',
    localAchatCash: 'local_achat_cash',
    fidVn: 'fid_vn',
    fidVo: 'fid_vo',
    securycar: 'securycar',
    manufacturer: 'constructeur',
};

export const leadLocalTypes = [
    leadTypes.local,
    leadTypes.localVd,
    leadTypes.localVo,
    leadTypes.localVn,
    leadTypes.localApv,
    leadTypes.localHomeCharge,
    leadTypes.localReprise,
    leadTypes.localAchatCash,
    leadTypes.fidVn,
    leadTypes.fidVo,
    leadTypes.securycar,
];

export const leadTypeSelectList = leadLocalTypes.filter(
    (type) => ![leadTypes.localApv, leadTypes.localHomeCharge].includes(type),
);

export const leadCommerceTypes = [
    leadTypes.local,
    leadTypes.localVd,
    leadTypes.localVo,
    leadTypes.localVn,
    leadTypes.localHomeCharge,
    leadTypes.localReprise,
    leadTypes.localAchatCash,
    leadTypes.fidVn,
    leadTypes.fidVo,
    leadTypes.securycar,
];

export interface Consent {
    id: number;
    name: string;
    slug: string;
    refDealership: string;
    gdprSms: null;
    gdprPhone: null;
    gdprPost: null;
    gdprEmail: null;
    created: string;
    updated: string;
    clientToken: null;
}

interface Business {
    lastActivityDate: string;
    lastActivityDealership: string;
    twelveMonthsTurnover: string;
    thirtysixMonthsTurnover: string;
    fullTurnover: string;
    turnoverCategories: {
        turnoverVo: {
            turnover: string;
            averageTurnover: string;
            activitiesCounter: string;
        };
        turnoverVn: {
            turnover: string;
            averageTurnover: string;
            activitiesCounter: string;
        };
        turnoverApv: {
            turnover: string;
            averageTurnover: string;
            activitiesCounter: string;
        };
    };
}

export interface Lead {
    id: number;
    customerProject: string;
    requestObject: string;
    communicationChannel: null;
    leadParentId: null;
    ownerId: number;
    createdBy: null;
    createdFirstname: string;
    createdLastname: string;
    ownerFirstname: string;
    ownerLastname: string;
    dealershipId: string;
    dealershipName: string;
    dealership: { make: string; id: string; usualName: string };
    created: string;
    updated: string;
    leadType: {
        name: string;
        slug: string;
        groupSlug: string;
    };
    leadOrigin: {
        name: string;
        slug: string;
        groupSlug: null;
    };
    leadSource: {
        name: string;
        slug: string;
    };
    isJoined: null;
    originId: string;
    lastUserId: number;
    lastUserFullname: string;
    lastStatusUpdate: string;
    leadElements: {
        id: number;
        designation: string;
        projectComment: null;
        vehicle: {
            id: number;
            make: string;
            model: string;
            version: null;
            registration: null;
            serialNumber: null;
            bodyType: null;
            fuelType: null;
            gearsType: null;
            mileage: null;
            productionYear: null;
            warranty: null;
            price: null;
            bodyColor: null;
            defaultImage: null;
            vehicleInternalId: null;
            reference: null;
            seoTitle: null;
            operations: {
                slug: string;
                name: string;
                start: string | null;
                end: string | null;
                crossed_out_price: number | null;
            }[];
            isFluidity: boolean;
            isSold: boolean;
            source360: null;
            player360: null;
            photosDefaults: null;
            images: null;
            nbImages: null;
            type: null;
            policeNumber: null;
        };
        priorityOrder: number;
    }[];
    isDeleted: boolean;
    isAutoUpdatable: true;
    fastNotes: FastNote[];
    comment: null;
    sviMainNumber: null;
    sviBackupNumber: null;
    sviCode: null;
    callTrackingStatus: null;
    callTrackingComment: null;
    lastStatus: {
        id: number;
        name: string;
        slug: string;
        subName: null;
    };
    leadStatus: {
        // deprecated use lastStatus instead
        id: number;
        name: string;
        slug: string;
        subName: null;
    };
    nextActions: {
        primary: string[];
        secondary: string[];
    };
    linkedLeads: Lead[];
    scheduleAppointment: { date: string; dateStart: string };
    contact: {
        id: number;
        civility: string;
        firstname: string;
        lastname: string;
        email: string;
        zipcode: string;
    };
    flags: string[];
    receipted: string;
}

export interface Event {
    eventId: number;
    leadId: null;
    leadParentId: null;
    leadSourceName: null;
    leadSourceSlug: null;
    eventName: string;
    eventTypeSlug: string;
    eventCreated: string;
    eventUserFirstname: null;
    eventUserLastname: null;
    leadStatusName: null;
    leadStatusSlug: null;
    leadStatusSubName: null;
    leadStatusUpdated: null;
    leadDealershipName: null;
    leadReceipted: null;
    leadTypeName: null;
    leadTypeSlug: null;
    actionId: null;
    vehicleId: null;
    vehicleUuid: null;
    brand: null;
    model: null;
    vin: null;
    registrationNumber: null;
    registrationNumberDate: null;
    sellingPrice: null;
    version: null;
    color: null;
    bodyType: null;
    lastMileageKnown: null;
    energy: null;
    leadElementsID: null;
    scheduleAppointmentId: null;
    scheduleAppointmentDateStart: null;
    scheduleAppointmentDateEnd: null;
    leadReasonSlug: null;
    leadReasonName: null;
    leadStatusNameHisto: null;
    leadStatusSubNameHisto: null;
    leadStatusSlugHisto: null;
    snapshotContactFirstname: string;
    snapshotContactLastname: string;
    actionEmailAttachHisto: string;
    actionEmailAttachPathHisto: string;
    eventRawFirstname: string;
    eventRawLastname: string;
    emailQueueId: null;
    emailQueueContent: null;
    emailQueueParams: null;
    emailQueueAttachments: null;
    apvVehicleMake?: string;
    apvVehicleModel?: string;
    apvVehicleRegistration?: string;
}

export interface Finance {
    id: number;
    financialType: string;
    financialAgency: null;
    financialBudget: number;
    financialRent: number;
    financialInput: number;
    status: null;
    interest: null;
    comment: null;
    deadline: null;
    contractNumber: string;
    contractStartDate: string;
    contractEndDate: string;
    residualValue: number;
    autofidFileNumber: null;
    contractExtensionDate: null;
    maximumContractExtensionDate: string;
}

export interface ListingParams {
    id: number;
    name: string;
    slug: string;
    isActive: boolean;
    listingParams: Array<ListingParams>;
    value: unknown | null;
    created: string;
    updated: string;
}

export interface CustomerParams {
    finance_type: ListingParams;
    purchase_order_tool: ListingParams;
    billing_order_tool: ListingParams;
    funding_agency: ListingParams;
}

export interface CustomerState {
    customers: {
        data: Customer[];
        count: number;
        loading: boolean;
        error: null;
    };
    selectedCustomers: Customer[];
    customer: {
        data: Customer;
        loading: boolean;
        contracts: Contract[];
        apvOrders: Order[];
        externalData: ExternalData[] | null;
        error: null;
        last_action?: any;
    };
    business: {
        error: null;
        loading: boolean;
        data: Business;
    };
    activity: {
        error: null;
        loading: boolean;
        data: CustomerVehicle[];
    };
    params: CustomerParams;
    finance: LeadDetails['finance'];
    leadReasons: null;
    tags: FastNote[];
}

const initialState: CustomerState = {
    customers: {
        data: [] as Customer[],
        count: 0,
        loading: false,
        error: null,
    },
    selectedCustomers: [] as Customer[],
    customer: {
        data: {} as Customer,
        contracts: [] as Contract[],
        apvOrders: [] as Order[],
        externalData: [] as ExternalData[],
        loading: false,
        error: null,
    },
    business: {
        error: null,
        loading: false,
        data: null,
    },
    activity: {
        error: null,
        loading: false,
        data: null,
    },
    params: null,
    finance: null,
    leadReasons: null,
    tags: null,
};

export function customerReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case actionTypes.ResetCustomers: {
            return produce(state, (draftState) => {
                draftState.customers = initialState.customers;
                draftState.selectedCustomers = initialState.selectedCustomers;
            });
        }
        case actionTypes.ResetCustomer: {
            return produce(state, (draftState) => {
                draftState.customer = initialState.customer;
                draftState.business = initialState.business;
                draftState.activity = initialState.activity;
            });
        }
        case actionTypes.FetchCustomers: {
            return produce(state, (draftState) => {
                draftState.selectedCustomers = initialState.selectedCustomers;
                draftState.customers = {
                    ...draftState.customers,
                    data: [],
                    count: 0,
                    loading: true,
                    error: null,
                };
            });
        }
        case actionTypes.FetchContracts: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    contracts: [],
                    loading: true,
                    error: null,
                };
            });
        }

        case actionTypes.FetchCustomer: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    data: {},
                    loading: true,
                    error: null,
                };
                draftState.activity = {
                    ...draftState.activity,
                    data: [],
                    loading: true,
                    error: null,
                };
            });
        }
        case actionTypes.AddCustomer:
        case actionTypes.UpdateCustomer: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    loading: true,
                    error: null,
                };
            });
        }
        case actionTypes.FetchedCustomers: {
            return produce(state, (draftState) => {
                draftState.customers = {
                    ...draftState.customers,
                    data: action.payload.result,
                    count: action.payload.count,
                    loading: false,
                    error: null,
                };
            });
        }
        case actionTypes.FetchedContracts: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    contracts: action.payload.result,
                    loading: false,
                    error: null,
                };
            });
        }
        case actionTypes.FetchedCustomer: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    data: action.payload.customer,
                    externalData: action.payload.externalData,
                    loading: false,
                    error: null,
                };
            });
        }
        case actionTypes.UpdatedCustomer:
        case actionTypes.AddedCustomer: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    loading: false,
                    data: action.payload,
                    error: null,
                };
            });
        }
        case actionTypes.AddCustomerFailed:
        case actionTypes.UpdateCustomerFailed: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    loading: false,
                    error: null,
                };
            });
        }
        case actionTypes.FetchCustomersFailed: {
            return produce(state, (draftState) => {
                draftState.customers = {
                    ...draftState.customers,
                    data: [],
                    count: 0,
                    loading: false,
                    error: action.payload,
                };
            });
        }
        case actionTypes.FetchContractsFailed: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    contracts: [],
                    loading: false,
                    error: action.payload,
                };
            });
        }
        case actionTypes.FetchCustomerFailed: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    data: {},
                    loading: false,
                    error: action.payload,
                };
            });
        }
        case actionTypes.LeadUpdated: {
            return produce(state, (draftState) => {
                draftState.customer.data = {
                    ...draftState.customer.data,
                    ...action.payload.customer,
                    loading: false,
                    leads: action.payload.leads,
                };

                draftState.customer.last_action = action.payload.action;
            });
        }
        case actionTypes.FetchedParams: {
            return produce(state, (draftState) => {
                draftState.params = action.payload;
            });
        }
        case actionTypes.FetchedFinance: {
            return produce(state, (draftState) => {
                draftState.finance = action.payload;
            });
        }
        case actionTypes.FetchedLeadReasons: {
            return produce(state, (draftState) => {
                draftState.leadReasons = action.payload;
            });
        }
        case actionTypes.FetchedTags: {
            return produce(state, (draftState) => {
                draftState.tags = action.payload;
            });
        }
        case actionTypes.FetchActionData: {
            const { type } = action.payload;
            return produce(state, (draftState) => {
                // @ts-ignore
                draftState[type] = {
                    // @ts-ignore
                    ...draftState[type],
                    loading: true,
                    data: null,
                    error: null,
                };
                draftState.customer.last_action = null;
            });
        }
        case actionTypes.FetchedActionData: {
            return produce(state, (draftState) => {
                // @ts-ignore
                draftState[action.payload.type].loading = false;
                // @ts-ignore
                draftState[action.payload.type].data = action.payload.result;
            });
        }
        case actionTypes.FetchActionDataFailed: {
            return produce(state, (draftState) => {
                // @ts-ignore
                draftState[action.payload.type].loading = false;
                // @ts-ignore
                draftState[action.payload.type].error = action.payload;
                // @ts-ignore
                draftState[action.payload.type].data = null;
            });
        }
        case actionTypes.FetchAPVOrders: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    apvOrders: [],
                    loading: true,
                    error: action.payload,
                };
            });
        }
        case actionTypes.FetchedAPVOrders: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    apvOrders: action.payload.orders ?? [],
                    loading: false,
                    error: null,
                };
            });
        }
        case actionTypes.FetchAPVOrdersFailed: {
            return produce(state, (draftState) => {
                draftState.customer = {
                    ...draftState.customer,
                    apvOrders: [],
                    loading: false,
                    error: null,
                };
            });
        }
        case actionTypes.AddSelectedCustomers: {
            return produce(state, (draftState) => {
                draftState.selectedCustomers.push(action.payload);
            });
        }
        case actionTypes.RemoveSelectedCustomers: {
            return produce(state, (draftState) => {
                draftState.selectedCustomers = draftState.selectedCustomers.filter((customer) => {
                    if (action.payload.id && customer.id === action.payload.id) {
                        return false;
                    } else if (action.payload.vcuId && customer.vcuId === action.payload.vcuId) {
                        return false;
                    }
                    return true;
                });
            });
        }
        default:
            return state;
    }
}

export const actions = {
    resetCustomers: () => ({ type: actionTypes.ResetCustomers }),
    fetchCustomer: () => ({ type: actionTypes.FetchCustomer }),
    resetCustomer: () => ({ type: actionTypes.ResetCustomer }),
    fetchContracts: () => ({ type: actionTypes.FetchContracts }),
    fetchedFinance: (finance: object) => ({ type: actionTypes.FetchedFinance, payload: finance }),

    fetchCustomers: () => ({ type: actionTypes.FetchCustomers }),
    addCustomer: () => ({ type: actionTypes.AddCustomer }),
    updateCustomer: () => ({ type: actionTypes.UpdateCustomer }),
    fetchedCustomer: (result: { customer: any; externalData: ExternalData[] }) => ({
        type: actionTypes.FetchedCustomer,
        payload: result,
    }),
    fetchedCustomers: (response: { result: Customer[]; count?: number }) => ({
        type: actionTypes.FetchedCustomers,
        payload: response,
    }),
    fetchedContracts: (response: { result: Contract[] }) => ({
        type: actionTypes.FetchedContracts,
        payload: response,
    }),
    updatedCustomer: (result: Customer) => ({ type: actionTypes.UpdatedCustomer, payload: result }),
    addedCustomer: (result: Customer) => ({ type: actionTypes.AddedCustomer, payload: result }),
    fetchCustomerFailed: (error: unknown) => ({
        type: actionTypes.FetchCustomerFailed,
        payload: error,
    }),
    fetchCustomersFailed: (error: unknown) => ({
        type: actionTypes.FetchCustomersFailed,
        payload: error,
    }),
    fetchContractsFailed: (error: unknown) => ({
        type: actionTypes.FetchContractsFailed,
        payload: error,
    }),
    addCustomerFailed: () => ({ type: actionTypes.AddCustomerFailed }),
    updateCustomerFailed: () => ({ type: actionTypes.UpdateCustomerFailed }),
    leadUpdated: (customer: Customer, leads: Lead[], type: string) => ({
        type: actionTypes.LeadUpdated,
        payload: { customer, leads, type },
    }),
    fetchedParams: (params: object) => ({ type: actionTypes.FetchedParams, payload: params }),
    fetchedLeadReasons: (result: object) => ({
        type: actionTypes.FetchedLeadReasons,
        payload: result,
    }),
    fetchedTags: (result: object) => ({
        type: actionTypes.FetchedTags,
        payload: result,
    }),
    fetchActionData: (type: string) => ({ type: actionTypes.FetchActionData, payload: type }),
    fetchedActionData: (result: object) => ({
        type: actionTypes.FetchedActionData,
        payload: result,
    }),
    fetchActionDataFailed: (error: {}) => ({
        type: actionTypes.FetchActionDataFailed,
        payload: error,
    }),
    fetchAPVOrders: () => ({ type: actionTypes.FetchAPVOrders }),
    fetchedAPVOrders: (result: Order[]) => ({ type: actionTypes.FetchedAPVOrders, payload: result }),
    fetchAPVOrdersFailed: (error: {}) => ({
        type: actionTypes.FetchAPVOrdersFailed,
        payload: error,
    }),
    addSelectedCustomers: (customer: Customer) => ({
        type: actionTypes.AddSelectedCustomers,
        payload: customer,
    }),
    removeSelectedCustomers: (customer: Customer) => ({
        type: actionTypes.RemoveSelectedCustomers,
        payload: customer,
    }),
};

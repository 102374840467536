import dayjs from 'dayjs';
import * as H from 'history';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Image, Row, Table } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';

import useModal from '@app//hooks/useModal';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';
import { fetchOrder } from '@app/store/apv/order/order.thunk';
import { RootState } from '@app/store/rootDuck';

import Loader from '@app/partials/content/Loader';
import Permission from '@app/partials/content/Permission';
import toast from '@app/partials/content/Toast';
import HeadBand from '@app/partials/layout/HeadBand';

import { getStateBySlug, HIDDEN_STATUS } from './Helpers/OrderStatus';
import Details from './Modals/Details';
import Update from './Modals/Update';

type OrderParams = {
    id: string;
};

export interface Package {
    id: number;
    name: string;
    slug: string;
    price: number;
    code: string;
    commercialOperationName: null;
    commercialOperationSlug: null;
    commercialOperationId: null;
    commercialOperationPrice: null;
    commercialOperationPercentage: null;
    totalPrice: number;
    created: string;
    updated: string;
    packageId: number;
    packagePriceId: null;
    commercialOperationTypeId: null;
    additionalServiceId: null;
    isAdditionalService: boolean;
    mandatory: boolean;
    mecaplanning: {
        duration: number;
    };
    isDegressivity: boolean;
    commercialOperationCategoryId: number;
}

function Order({ history }: { history?: H.History }) {
    const Intl = useIntl();
    const methods = useFormContext();
    const params = useParams<OrderParams>();
    const dispatch = useDispatch();
    const [firstLoad, setFirstLoad] = useState(true);
    const { order, error, loading } = useSelector(({ order }: RootState) => order);
    const [showUpdateModal, toggleUpdateModal] = useModal(false);
    const [showDetailsModal, toggleDetailsModal] = useModal(false);

    const showOrders = useCallback(() => {
        history.push(routeTo(ROUTES.ORDERS.PATH.ALL));
    }, [history]);

    const isFree = (price: number) => {
        if (price === 0) return true;
        return false;
    };

    useEffect(() => {
        dispatch(fetchOrder(params.id));
        setFirstLoad(false);
    }, [dispatch, params.id]);

    useEffect(() => {
        if (error !== null && !firstLoad) {
            toast({
                onShow: showOrders,
                variant: 'danger',
                message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
            });
        }
    }, [error, firstLoad, showOrders]);

    const onSubmit = (data: any) => console.log(data);

    if (loading || order === null) {
        return <Loader style={{ width: '5rem', height: '5rem' }} />;
    }

    return (
        <Form className="package" onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mb-5">
                <HeadBand variant={getStateBySlug(order)}>
                    <Row className="col-lg-12">
                        <Col lg={7}>
                            <Row>
                                <Col lg={6}>
                                    <Image
                                        className="float-right"
                                        src="/media/illustrations/rendez-vous-confirme.svg"
                                    />
                                </Col>
                                <Col className="headband__col" lg={6}>
                                    <div className="headband__title">
                                        <i className="la la-bullhorn mr-3" />
                                        <span className="font-weight-semibold">{order?.orderStatus?.name}</span>
                                    </div>
                                    <span className="font-weight-semibold">
                                        {' '}
                                        {order?.firstname} {order?.lastname}{' '}
                                    </span>
                                    a rendez-vous
                                    <span className="font-weight-semibold">
                                        {order?.appointment &&
                                            dayjs(order.appointment.dateStart).format(' [le] DD MMMM[, à ] HH [h] mm')}
                                    </span>
                                    ,
                                    <br />
                                    dans la concession
                                    <span className="font-weight-semibold">
                                        {' '}
                                        {order?.dealership?.dealershipSource?.usual_name}
                                    </span>
                                    .
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </HeadBand>
            </div>
            <Row className="mb-5">
                <Col lg={4}>
                    <Card>
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <div>
                                <i className="card__icon fas fa-file-invoice" />
                                {Intl.formatMessage({ id: 'APV.ORDER.VIEW.BILLING' })}
                            </div>
                            {order?.totalOrderAmount !== 0 && (
                                <Button variant="outline-light" onClick={() => toggleDetailsModal(true)}>
                                    <i className="mr-1 fas fa-file-invoice" />
                                    {Intl.formatMessage({ id: 'APV.ORDER.VIEW.MOREDETAILS' })}
                                </Button>
                            )}
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.NAME' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.lastname && <span>{order.lastname}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.FIRSTNAME' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.firstname && <span>{order.firstname}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.LOGIN' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.autosphereId && <span>Autosphere {order.autosphereId}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.MAIL' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.email && <span>{order.email}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.MOBILE' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.mobile ? order.mobile : order?.phone ? order.phone : <></>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.ADDRESS' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.address && (
                                        <span>
                                            {order.address}
                                            <br />
                                            {order.zipcode} {order.city}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <div>
                                <i className="card__icon fas fa-calendar-alt" />
                                {Intl.formatMessage({ id: 'APV.ORDER.VIEW.APPOINTMENT' })}
                            </div>
                            {order?.appointment && !HIDDEN_STATUS.includes(order?.orderStatus?.slug) && (
                                <Permission permissions={['APV_UPDATE_ORDER']}>
                                    <Button variant="outline-light" onClick={() => toggleUpdateModal(true)}>
                                        {Intl.formatMessage({ id: 'APV.ORDER.VIEW.UPDATE' })}
                                    </Button>
                                </Permission>
                            )}
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.HOUR' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.appointment &&
                                        dayjs(order.appointment.dateStart).format(' [le] DD MMMM[, à ] HH [h] mm')}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.DEALERSHIP' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.dealership?.dealershipSource && (
                                        <span>{order.dealership.dealershipSource.usual_name}</span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.DEALERSHIP' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.dealership?.dealershipSource && (
                                        <span>
                                            {order.dealership.dealershipSource.address}
                                            <br />
                                            {order.dealership.dealershipSource.zipcode}{' '}
                                            {order.dealership.dealershipSource.city}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.COMMENT' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.comment && <span>{order.comment}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.APPOINTMENT.ID' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.mecaplanning?.rdvId && <span>{order.mecaplanning.rdvId}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.STATUS' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.mecaplanning?.status && <span>{order.mecaplanning.status}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.RECEIVER' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.mecaplanning?.reception && <span>{order.mecaplanning.reception}</span>}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header className="d-flex align-items-center justify-content-between">
                            <div>
                                <i className="card__icon fas fa-car" />
                                {Intl.formatMessage({ id: 'APV.ORDER.VIEW.VEHICLE_DETAILS' })}
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.BRAND' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.make}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.MODEL' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.model}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.VERSION' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.version}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.DATE_REGISTRATION' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {dayjs(order?.dateFirstRegistration).format('DD/MM/YYYY')}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.MILEAGE' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    <NumberFormat
                                        value={order?.mileage}
                                        thousandSeparator=" "
                                        displayType="text"
                                        suffix=" km"
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>{Intl.formatMessage({ id: 'APV.ORDER.VIEW.REGISTRATION' })} :</Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.registration && <span>{order.registration}</span>}
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col lg={6}>
                                    {Intl.formatMessage({ id: 'APV.ORDER.VIEW.DETAILS.NEXT.TECHNICAL.INSPECT.DATE' })} :
                                </Col>
                                <Col className="font-weight-semibold" lg={6}>
                                    {order?.nextTechnicalInspectDate ? (
                                        <span>{dayjs(order?.nextTechnicalInspectDate).format('DD/MM/YYYY')}</span>
                                    ) : (
                                        '-'
                                    )}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header className="border-bottom-0">
                            <i className="card__icon fas fa-tasks" />
                            {Intl.formatMessage({ id: 'APV.ORDER.VIEW.DETAILS' })}
                        </Card.Header>
                        <Card.Body className="p-0">
                            <Table className="m-0" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th style={{ width: '10%' }}>
                                            {Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.REFERENCE' })}
                                        </th>
                                        <th style={{ width: '35%' }}>
                                            {Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.PRODUCT' })}
                                        </th>
                                        <th style={{ width: '15%' }}>
                                            {Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.COMMERCIAL_OPERATION' })}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.INITIAL_PRICE' })}
                                        </th>
                                        <th style={{ width: '20%' }}>
                                            {Intl.formatMessage({ id: 'APV.ORDER.TABLE.HEAD.FINAL_PRICE' })}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {order?.orderPackages &&
                                        order.orderPackages.map((_package: Package, key: number) => (
                                            <tr key={key}>
                                                <td>{_package.code}</td>
                                                <td>{_package.name}</td>
                                                <td>{_package.commercialOperationName}</td>
                                                <td>
                                                    {!isFree(_package.price) ? (
                                                        <NumberFormat
                                                            value={_package.price}
                                                            thousandSeparator=" "
                                                            decimalSeparator=","
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            displayType="text"
                                                            suffix=" €"
                                                        />
                                                    ) : (
                                                        Intl.formatMessage({ id: 'APV.ORDER.VIEW.FREE' })
                                                    )}
                                                </td>
                                                <td>
                                                    {!isFree(_package.totalPrice) ? (
                                                        <NumberFormat
                                                            value={_package.totalPrice}
                                                            thousandSeparator=" "
                                                            decimalSeparator=","
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            displayType="text"
                                                            suffix=" €"
                                                        />
                                                    ) : (
                                                        Intl.formatMessage({ id: 'APV.ORDER.VIEW.FREE' })
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                                <tfoot className="tfoot tfoot--total">
                                    <tr>
                                        <td colSpan={3} />
                                        <td className="font-weight-bold">
                                            <div className="tfoot__bordered tfoot__total">
                                                {Intl.formatMessage({ id: 'APV.ORDER.VIEW.TOTAL' })}:
                                            </div>
                                        </td>
                                        <td className="font-weight-bold">
                                            <div className="tfoot__bordered tfoot__number">
                                                {!isFree(order?.totalOrderAmount) ? (
                                                    <NumberFormat
                                                        value={order?.totalOrderAmount}
                                                        thousandSeparator=" "
                                                        decimalSeparator=","
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        displayType="text"
                                                        suffix=" €"
                                                    />
                                                ) : (
                                                    Intl.formatMessage({ id: 'APV.ORDER.VIEW.FREE' })
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Update showModal={showUpdateModal} setShowModal={toggleUpdateModal} />
            <Details showModal={showDetailsModal} order={order} setShowModal={toggleDetailsModal} />
        </Form>
    );
}

export default withRouter(Order);

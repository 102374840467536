import { z } from 'zod';

import {
    AchatSchema,
    ActionsBlockSchema,
    BCS_Schema,
    BLOC_ACHAT_FINANCEMENT_Schema,
    BLOC_APRES_VENTE_Schema,
    BLOC_GARANTIE_EXTENSION_GARANTIE_Schema,
    BLOC_SERVICES_AUTOSPHERE_Schema,
    CIRANO_Schema,
    CONTRAT_DE_MAINTENANCE_Schema,
    FACTURE_APV_Schema,
    FinancementSchema,
    OPTEVEN_Schema,
    SECURYCAR_Schema,
    TSCAN_Schema,
    VEHICLE_INFO_Schema,
    VEHICLE_Schema,
} from './schemas/ActionsBlock.schema';

export enum ActionsBlockSections {
    BLOC_ACHAT_FINANCEMENT = 'BLOC_ACHAT_FINANCEMENT',
    BLOC_APRES_VENTE = 'BLOC_APRES_VENTE',
    BLOC_GARANTIE_EXTENSION_GARANTIE = 'BLOC_GARANTIE_EXTENSION_GARANTIE',
    BLOC_SERVICES_AUTOSPHERE = 'BLOC_SERVICES_AUTOSPHERE',
}

export type ActionsBlock = z.infer<typeof ActionsBlockSchema>;
export type ActionsBlockVehicle = z.infer<typeof VEHICLE_Schema>;
export type ActionsBlockVehicleInfo = z.infer<typeof VEHICLE_INFO_Schema>;

export type ActionsBlockFundingEvents = z.infer<typeof BLOC_ACHAT_FINANCEMENT_Schema>;
export type ActionsBlockBuyEvent = z.infer<typeof AchatSchema>;
export type ActionsBlockFundingEvent = z.infer<typeof FinancementSchema>;

export type ActionsBlockAfterSalesEvents = z.infer<typeof BLOC_APRES_VENTE_Schema>;
export type ActionsBlockBCSEvent = z.infer<typeof BCS_Schema>;
export type ActionsBlockTSCANEvent = z.infer<typeof TSCAN_Schema>;
export type ActionsBlockAPVEvent = z.infer<typeof FACTURE_APV_Schema>;

export type ActionsBlockWarrantyEvents = z.infer<typeof BLOC_GARANTIE_EXTENSION_GARANTIE_Schema>;
export type ActionsBlockOptevenEvent = z.infer<typeof OPTEVEN_Schema>;
export type ActionsBlockCiranoEvent = z.infer<typeof CIRANO_Schema>;

export type ActionsBlockServicesEvents = z.infer<typeof BLOC_SERVICES_AUTOSPHERE_Schema>;
export type ActionsBlockSecuryCarEvent = z.infer<typeof SECURYCAR_Schema>;
export type ActionsBlockMaintenanceEvent = z.infer<typeof CONTRAT_DE_MAINTENANCE_Schema>;

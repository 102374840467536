import { checkPermission, getPermissions } from '@app/hooks/usePermissions';

import ApiGateway, { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { PricingDetails, PricingState, Vehicle, VehicleHistoricalPrice } from '@app/store/stock/stock.type';
import store from '@app/store/store';

const { REACT_APP_LEAD_URL, REACT_APP_STOCK_URL, REACT_APP_STOCK_ENV, REACT_APP_STOCK_ENV_ABE } = process.env;

const getUserData = () => store.getState().auth.userData;

interface ApiResponse<T> {
    result: T & Error;
}

export interface VehiclesResponse {
    total: number;
    results: {
        _data: Vehicle;
        _auto_populate: boolean;
    }[];
    facets: object;
    sorts: string[];
}

interface PricingError {
    error?: null;
}

export interface VehicleHistoricalPricesResponse {
    total: number;
    results: VehicleHistoricalPrice[] & PricingError;
}

export interface VehicleResponse {
    error?: null & Vehicle;
}

interface PricingReasonsResponse {
    message: string;
    result: {
        id: number;
        description: string;
    }[];
}

export function getVehicles(params: unknown & { context: string }) {
    const { context } = params;
    delete params.context;

    let indexStock = REACT_APP_STOCK_ENV;

    if (context === 'efb') {
        indexStock = REACT_APP_STOCK_ENV_ABE;
    }

    return ApiGateway.get<VehiclesResponse, CustomAxiosResponse<VehiclesResponse>>(
        `${REACT_APP_STOCK_URL}/${indexStock}`,
        {
            params,
        },
    );
}

export function getVehicle(sku: string, context: string) {
    let indexStock = REACT_APP_STOCK_ENV;

    if (context === 'efb') {
        indexStock = REACT_APP_STOCK_ENV_ABE;
    }
    return ApiGateway.get<never, VehicleResponse>(`${REACT_APP_STOCK_URL}/${indexStock}/${sku}`);
}

export function getVehicleHistoricalPrice(sku: string) {
    return ApiGateway.get<never, VehicleHistoricalPricesResponse>(
        `${REACT_APP_STOCK_URL}/vehicle-historical-value/${sku}/price?withPricingHistorical=true`,
    );
}

type AcceptPriceRecoProps = {
    [key: string]: {
        newPrice: number;
        reasonId?: number;
        comment?: string;
    };
};

interface ErrorResponse {
    success: boolean;
    statusCode: number;
    error: string;
}

export function acceptVehiclePriceRecommendation(params: AcceptPriceRecoProps[]) {
    return ApiGateway.post<ErrorResponse[], CustomAxiosResponse<ErrorResponse[]>>(
        `${REACT_APP_STOCK_URL}/pricing/confirm`,
        params,
    );
}

type UpdatePriceProps = {
    [key: string]: number;
};

export function updateVehiclePrice(params: UpdatePriceProps) {
    return ApiGateway.post(`${REACT_APP_STOCK_URL}/vehicle-information/bulk/price-update`, params);
}

type RefusePriceRecoProps = {
    [key: string]: {
        reasonId: number;
        comment: string;
    };
};

export function refuseVehiclePriceRecommendation(params: RefusePriceRecoProps[]) {
    return ApiGateway.post(`${REACT_APP_STOCK_URL}/pricing/cancel`, params);
}

export function confirmVehiclePriceModification(skus: string[]) {
    return ApiGateway.post(`${REACT_APP_STOCK_URL}/pricing/check/confirm`, {
        internalIds: skus,
    });
}

export type PriceModificationProps = {
    [key: string]: {
        newPrice: number;
        reasonId: number;
        comment: string;
    };
};

export function modifyVehiclePriceModification(params: PriceModificationProps[]) {
    return ApiGateway.post(`${REACT_APP_STOCK_URL}/pricing/check/modify`, params);
}

export function getPricingReasonsByStatusSlug(slug: string) {
    return ApiGateway.get<PricingReasonsResponse, CustomAxiosResponse<PricingReasonsResponse>>(
        `${REACT_APP_STOCK_URL}/pricing/reasons/${slug}`,
    );
}

export function getVehicleDetailClient(sku: string) {
    return ApiGateway.get(`${REACT_APP_STOCK_URL}/vehicle-counter/client/${sku}`);
}

export function getVehicleCounterDetail({ internalId, ...params }: { internalId: string }) {
    return ApiGateway.get(`${REACT_APP_STOCK_URL}/vehicle-counter/${internalId}`, {
        params,
    });
}

type VehiclesActionsToDoParams = {
    type?: number;
    inactive?: number;
    page?: number;
    limit?: number;
    sortField?: string;
    sortDirection?: 'asc' | 'desc';
    dealer_id?: string;
    immat?: string;
};

export function getVehiclesActionsToDo(params: VehiclesActionsToDoParams) {
    return ApiGateway.get(`${REACT_APP_STOCK_URL}/actions-todo`, {
        params,
    });
}

export function updateAnomaly({ anomalyId, ...params }: { anomalyId: string }) {
    return ApiGateway.put(`${REACT_APP_STOCK_URL}/actions-todo/${anomalyId}/done`, {
        ...params,
    });
}

export interface AutoVistaPricing {
    internal_id: string;
    registration: string;
    nationalAskingPrice: string;
    spot: string;
    averageSellingTime: number;
    radar: string;
    recoPrice: number;
    error?: null;
}

interface VehicleLeads {
    version: string;
    action: string;
    timestamp: string;
    counter: [
        {
            key: string;
            doc_count: number;
            label: string;
        },
    ];
    facets: {
        leadStatus: [];
        leadSources: [];
        leadTypesGroups: [];
        leadTypes: [];
        leadOrigins: [];
        dealershipIds: [];
    };
    count: number;
}

export function getVehicleLeads(vehicleIdentifier: string) {
    return ApiGateway.get<never, VehicleLeads>(
        `${REACT_APP_LEAD_URL}/lead/status/all?vehicleIdentifier=${vehicleIdentifier}`,
    );
}

export function getIndicators(params: object) {
    return ApiGateway.get(`${REACT_APP_STOCK_URL}/indicator`, {
        params,
    });
}

interface ActionToDoCounter {
    anomalies: unknown;
}

export async function getStockActionToDoCounters() {
    const counters = await ApiGateway.get<
        ApiResponse<ActionToDoCounter>,
        CustomAxiosResponse<ApiResponse<ActionToDoCounter>>
    >(`${REACT_APP_STOCK_URL}/indicator?indicators[]=anomalies`);
    return counters?.result?.anomalies;
}

interface PricingToDoCounter {
    pricing_to_process?: number;
    pricing_to_check?: number;
}

export async function getStockPricingToDoCounters() {
    let counters;

    const user = getUserData();
    const isAdmin = checkPermission(getPermissions(user), 'STOCK_CHECK_PRICING');

    if (isAdmin) {
        counters = await ApiGateway.get<ApiResponse<PricingToDoCounter>, CustomAxiosResponse<PricingToDoCounter>>(
            `${REACT_APP_STOCK_URL}/indicator?indicators[]=pricing_to_process&indicators[]=pricing_to_check`,
        );
        return (counters?.pricing_to_process || 0) + (counters?.pricing_to_check || 0);
    }

    counters = await ApiGateway.get<
        ApiResponse<PricingToDoCounter>,
        CustomAxiosResponse<ApiResponse<PricingToDoCounter>>
    >(`${REACT_APP_STOCK_URL}/indicator?indicators[]=pricing_to_process`);

    return counters?.result?.pricing_to_process;
}

export async function getStockPricingToDoCountersRaw() {
    const counters = await ApiGateway.get<
        ApiResponse<PricingToDoCounter>,
        CustomAxiosResponse<ApiResponse<PricingToDoCounter>>
    >(`${REACT_APP_STOCK_URL}/indicator?indicators[]=pricing_to_process&indicators[]=pricing_to_check`);
    return counters;
}

export async function getPricingDetails() {
    return ApiGateway.get<PricingDetails, CustomAxiosResponse<PricingDetails>>(
        `${REACT_APP_STOCK_URL}/pricing/details`,
    );
}

export function getPricingState() {
    return ApiGateway.get<PricingState, CustomAxiosResponse<PricingState>>(
        `${REACT_APP_STOCK_URL}/pricing-launcher/state`,
    );
}

export function createPricingRequest() {
    return ApiGateway.post(`${REACT_APP_STOCK_URL}/pricing-launcher/request/new`);
}

export function cancelPricingRequest() {
    return ApiGateway.post(`${REACT_APP_STOCK_URL}/pricing-launcher/request/cancel`);
}
